
<div class="order-summary">
  <div class="order__details">
    <div class="order__image"><img [bwEcommImage]="order.product.imageUrls[0]" width="250" [attr.alt]="order.product?.name"></div>
    <div class="order__content">
      <div class="content__info">
        <!-- Product Header-->
        <div class="info__header">
          <div class="header-left">
            <div class="info__collection-name" *featureEnabled="'COLLECTION_NAME'">{{order.product.collectionName}}</div>
            <div class="info__name">{{order.product.name}}</div>
          </div>
          <ng-container *inExperiment="{name: 'CHECKOUT_OASYS_PRICE', variants: [0]}">
            <div class="info__price" *ngIf="order.price">{{order.price | price}}<span class="price__discount" *ngIf="order.price.discount">{{order.price | price:'original'}}</span></div>
          </ng-container>
          <ng-container *inExperiment="{name: 'CHECKOUT_OASYS_PRICE', variants: [1]}">
            <ui-price *ngIf="order.price" price="{{order.price | price}}" price_before_discount="{{(order.price.discount ? order.price : undefined )| price:'original'}}" price_type="primary"></ui-price>
          </ng-container>
        </div>
        <!-- Subscription Duration-->
        <div class="info__type" *ngIf="order.type !== 'oneoff'">{{order.duration | duration}} Subscription, &nbsp;<span *ngIf="order.duration &gt; 0 &amp;&amp; !order.product.bundleOnly &amp;&amp; !order.product.subscriptionOnly">starting with {{order.product.name}}</span></div>
        <!-- Addon Badge-->
        <div class="delivery__addon no-badge" *ngIf="order.addons &amp;&amp; order.addons.length">
          <div class="badge badge-pill badge-light light" *ngFor="let addon of (order.addons | addonType:['standard_gift_card']:true)">+ {{addon.name}}</div>
        </div>
        <bw-purchase-basket-discount-message [order]="order"></bw-purchase-basket-discount-message>
      </div>
      <div class="info__delivery" data-hj-suppress>
        <!-- Recipient-->
        <ng-container *ngIf="order?.product?.type !== 'digital_gift_voucher'">
          <p class="section-title">Recipient</p>
          <div class="delivery__address">
            <div><span class="recipient-name">To &nbsp;</span>{{order.address.name}}</div>
            <div *ngIf="order.address.line1">
              <ng-container *ngIf="!postcodeBeforeCityEnabled"><span [innerHtml]="order.address | address:false:false:true"></span></ng-container>
              <ng-container *ngIf="postcodeBeforeCityEnabled"><span [innerHtml]="order.address | address:false:true:true"></span></ng-container>
            </div>
          </div>
        </ng-container>
        <!-- Digital Gift Voucher Recipient-->
        <ng-container *ngIf="order?.product?.type === 'digital_gift_voucher' &amp;&amp; user?.email?.address">
          <p class="section-title">Recipient</p>
          <div class="delivery__address">
            <div><span class="recipient-name">To &nbsp;</span>{{user.email.address}}</div>
          </div>
        </ng-container>
        <!-- Delivery Details-->
        <ng-container *ngIf="order.firstDeliveryDate || order.timeslot">
          <p class="section-title">Delivery</p>
          <div class="delivery__date"><span *ngIf="!dateIsToday(order.firstDeliveryDate)">{{order.firstDeliveryDate.toDate() | localeDate:'dddd, MMMM D'}}</span><span *ngIf="dateIsToday(order.firstDeliveryDate)">Today</span><span *ngIf="order.timeslot &amp;&amp; order.timeslot.start">&nbsp; &#64; {{order.timeslot.localStart.toDate() | localeDate:'h:mma'}}</span><span *ngIf="order.timeslot &amp;&amp; order.timeslot.end">&nbsp; - {{order.timeslot.localEnd.toDate() | localeDate:'h:mma'}}</span><br><span *ngIf="order?.shippingOption">{{order.shippingOption.name}}</span></div>
        </ng-container>
        <div class="badge badge-pill badge-light" *ngIf="order?.shippingOption?.successRateMessage"><span>{{order.shippingOption.successRateMessage}}</span></div>
        <!-- For optional actions-->
        <ng-content select="[role=content-actions]"></ng-content>
      </div>
    </div>
  </div>
</div>