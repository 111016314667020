import { Injectable } from '@angular/core';
import { Country } from 'Shared/classes/country';
import { Product, ProductType } from 'Shared/classes/product';
import * as dayjs from 'dayjs';
import { CarouselSegment, ContentSegment } from 'Shared/models/segment-model.service';
import { Discount } from 'Shared/classes/discount';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { Config, ConfigService } from 'Shared/services/config.service';
import { StateService } from 'Shared/services/state.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { SegmentService } from 'Shared/services/segment.service';
import { User } from 'Shared/classes/user';
import { ProductService } from 'Checkout/services/product.service';
import { ImageSizePipe } from 'Shared/pipes/image-size.pipe';
import { GridProduct } from 'Shared/classes/grid-product';
import { BoostedProductExperiment } from 'Shared/classes/boosted-product-experiment';
import { Favourite } from 'Shared/classes/favourite';
import { FavouritesService } from 'Shared/services/favourites.service';
import { ContentService } from 'Shared/services/content.service';
import { SortService } from 'Checkout/services/sort.service';
import { CarouselModelService } from 'Checkout/models/carousel-model.service';
import { Carousel } from 'Shared/classes/carousel';
import { FeaturesService } from 'Shared/services/features.service';
import { CountryService } from 'Shared/services/country.service';
import { BehaviorSubject } from 'rxjs';
import { UserService } from 'Shared/services/user.service';
import { LocationService } from 'Shared/services/location.service';
import { OptionalRangeProductParams, RangeProductFilter } from 'Shared/classes/range-products';
import { DiscountService } from 'Checkout/services/discount.service';

export { Product } from 'Shared/classes/product';

export interface ListType {
  type: string;
  value?: string;
}

export enum GridExclusionRule {
  ExcludeBundle = 'excludeBundles',
  ExcludeSubscriptions = 'excludeSubscriptions'
}

interface GridProductInterface {
  site: Country;
  shippingTo: Country;
  serverTime?: dayjs.Dayjs; // TODO: is needed?
  orderIndex: number;
  listType: ListType;

  // Optional
  user?: User;
  discount?: Discount;
  availableForDeliveryOn?: dayjs.Dayjs;
  bouquet?: string;
  sortBy?: string;
  exclusionRules?: GridExclusionRule[];
  // TODO: sortDirection - ascending / asc should be the default
}

@Injectable({
  providedIn: 'root'
})
export class ProductGridService {
  public productsRefreshed$ = new BehaviorSubject<any>({});
  public productsAreRefreshed$ = new BehaviorSubject<any>(true);
  initialProducts = {};
  carousels: Carousel[] = [];

  constructor(
    private segmentService: SegmentService,
    private analyticsService: AnalyticsService,
    private configService: ConfigService,
    private experimentService: ExperimentsService,
    private productService: ProductService,
    private imageSizePipe: ImageSizePipe,
    private favouritesService: FavouritesService,
    private contentService: ContentService,
    private sortService: SortService,
    private carouselModelService: CarouselModelService,
    private featuresService: FeaturesService,
    private countryService: CountryService,
    private userService: UserService,
    private stateService: StateService,
    private locationService: LocationService,
    private discountService: DiscountService
  ) {}

  // Try to call the sku ordering service(getCarouselsForUser)
  // If it succeeds, call the getUserLevelPersonalisedProducts
  // If it fails, call the getProductsForUserBySegment
  // If we are on the BW_RANGE_GRID experiment, call the range-discovery service(getRangeProducts)
  getProducts(
    params: GridProductInterface
  ): Promise<{ products: GridProduct[]; filters?: RangeProductFilter[]; activeSegment?: CarouselSegment }> {
    const isSkuOrderingEnabled = this.featuresService.getFeature('SKU_ORDERING_ENABLED');

    if (
      (this.stateService.getCurrent().name === 'checkout.base' || this.stateService.getCurrent().name === 'checkout.tagOnly') &&
      this.experimentService.isActive('BW_RANGE_GRID', 1)
    ) {
      return this.getRangeProducts(params);
    } else {
      if (isSkuOrderingEnabled) {
        return this.carouselModelService
          .getCarouselsForUser(params.shippingTo, params.user)
          .then((res) => {
            this.carousels = res;
            return this.getUserLevelPersonalisedProducts(params);
          })
          .catch(() => this.getProductsForUserBySegment(params));
      } else {
        return this.getProductsForUserBySegment(params);
      }
    }
  }

  /**
   * Get range products from the product service and convert them to more useful 'Grid Products' with additional properties
   * @param params
   */
  getRangeProducts(params: OptionalRangeProductParams): Promise<{ products: GridProduct[]; filters: RangeProductFilter[] }> {
    const user = this.userService.getUser();
    const bouquetSlug = this.stateService.getCurrent().params?.bouquet as string;
    const discountCode = this.stateService.getCurrent().params?.discountCode as string;
    const listType = this.locationService.getListType() as ListType;
    const prioritisedTag = listType.type === 'tag' ? listType.value : undefined;
    const filteredTagonly = listType.type === 'tagOnly' ? listType.value : undefined;
    const brandId = this.configService.getConfig().brandId;
    const country = this.countryService.forShipping;
    const pageSize = params['pageSize'] ? params['pageSize'] : 1000;
    const orderIndex = params['orderIndex'] ? params['orderIndex'] : 0;

    // TODO: in here we should add the functionalities discussed in the document that have to be done by the BackEnd
    // https://docs.google.com/spreadsheets/d/1kmyxjK49kAHKiNkx-BHOhHy3c_gxcRqFxfuMtLg0Xk8/edit#gid=0

    // Ideally the BE should validate the discount code(without calling the v2/codes endpoint), but at the moment this is not supported
    let validatedDiscountCodePromise: Promise<string | undefined>;

    if (discountCode) {
      validatedDiscountCodePromise = this.discountService
        .check(new Discount(discountCode), country)
        .then(
          (): string =>
            // Valid discount code case, return the discount code
            discountCode
        )
        .catch(
          (): undefined =>
            // Invalid discount code case, return undefined
            undefined
        );
    } else {
      // No discount code present, return undefined
      validatedDiscountCodePromise = Promise.resolve(undefined);
    }
    return validatedDiscountCodePromise
      .then(
        (validatedDiscountCode): Promise<[{ availableProducts: Product[]; availableFilters: RangeProductFilter[] }, Favourite[]]> =>
          Promise.all([
            this.productService.getAvailableRangeProducts({
              ...params,
              bouquetSlug,
              brandId,
              country,
              filteredTagonly,
              pageSize,
              prioritisedTag,
              orderIndex,
              user,
              validatedDiscountCode // Pass validated discount code or undefined
            }),
            this.favouritesService.getFavourites(country).catch((): Favourite[] => [])
          ])
      )
      .then(([{ availableProducts, availableFilters }, favourites]): { products: GridProduct[]; filters: RangeProductFilter[] } => {
        // TODO: in here we should add the functionalities discussed in the document that have to be done by the FrontEnd
        // This is only for manipulating the 10 products that are returned
        // https://docs.google.com/spreadsheets/d/1kmyxjK49kAHKiNkx-BHOhHy3c_gxcRqFxfuMtLg0Xk8/edit#gid=0

        const gridProducts = this.toGridProduct(availableProducts, favourites, user);
        return { products: gridProducts, filters: availableFilters };
      });
  }

  /**
   * Get user-level personalised products
   * @param params
   */
  getUserLevelPersonalisedProducts(params: GridProductInterface): Promise<{
    products: GridProduct[];
    activeSegment?: CarouselSegment;
  }> {
    const listType = params.listType;
    const lType = (listType.type || '').toLowerCase();
    const lValue = typeof listType.value === 'string' ? (listType.value || '').toLowerCase() : '';

    const config = this.configService.getConfig() || ({} as Config);

    return Promise.all([
      this.productService.getAvailableProducts(params.shippingTo, params.orderIndex, params.discount),
      this.contentService.getContentSegments().catch((e) => []),
      this.favouritesService.getFavourites(params.shippingTo).catch((e) => [])
    ]).then(([availableProducts, contentSegments, favourites]) => {
      let products: Product[] = availableProducts;

      // We only want to show the cheapest voucher, physical takes priority
      products = this.filterOutTypeExceptCheapest(products, ['physical_gift_voucher', 'digital_gift_voucher']);

      // If it's the base carousel, find it, otherwise, find a match or use fallback
      // prettier-ignore
      let matchedCarousel =
        (lType === 'base' && this.carousels.find(c => c.type === 'base')) ||
        this.carousels.find(c => (c.type || '').toLowerCase() === lType && (c.typeValue || '').toLowerCase() === lValue) ||
        this.carousels.find(c => (c.type || '') === 'fallback');

      // If we can't get a carousel - use a very generic fallback based on products API
      if (!matchedCarousel) {
        matchedCarousel = new Carousel();
        matchedCarousel.type = 'fallback';
        matchedCarousel.userSegmentId = 0;
        matchedCarousel.productIds = products.map((p) => p.id);
      }

      // Wait for the product Ids for experiment
      return this.getProductIdsIfInExperiment(matchedCarousel).then((productIds) => {
        // For express, we don't take carousels into account
        products = this.filterProductsByIds(productIds, products);

        // Initially filter the products
        if (matchedCarousel.type !== 'fallback') {
          // All is good - we've found a suitable carousel
        } else if (lType === 'tagonly') {
          products = products.filter((p) => (p.tags || []).indexOf(lValue) > -1);
        } else if (lType === 'tag') {
          const matching = products.filter((p) => (p.tags || []).indexOf(lValue) > -1);
          const notMatching = products.filter((p) => (p.tags || []).indexOf(lValue) < 0);
          products = matching.concat(notMatching);
        }

        // Exclude any tag by segment request
        products = this.excludeTagsBasedOnSegment(products, contentSegments);

        // Exclude any by tag
        products = this.experimentTagExclude(products, listType, config.web_exclude_tags);

        // Exclude any by tag if under feature flag
        if (this.featuresService.getFeature('GENERIC_SUBSCRIPTIONS')) {
          products = this.excludeTagsBasedOnSubscriptions(products, listType);
        }

        // swap any products
        products = this.experimentProductSwap(products, availableProducts, config.SKU_SWAPPING_CAROUSEL);

        // If it's a bouquet link, we need to ensure it's available from the main products API
        if (params.bouquet) {
          products = this.ensureAvailable(products, availableProducts, params.bouquet);
        }

        // Convert to more useful 'Grid Products' with additional properties
        let gridProducts = this.toGridProduct(products, favourites, params.user);

        // Do any sorting first, we want the bouquet= and ?availableForDeliveryOn to come last
        if (params.sortBy) {
          gridProducts = this.sortService.sortProducts(params.sortBy, gridProducts);
        }

        // If it's a bouquet URL, bring it to the front - this "trumps" everything, except date
        if (params.bouquet) {
          gridProducts = this.moveToFront(gridProducts, params.bouquet);
        }

        // If we want to see what's potentially available for a future date
        if (params.availableForDeliveryOn) {
          gridProducts = this.filterProductsForDeliverable(gridProducts, params.availableForDeliveryOn);
        }

        return {
          // TODO: Once the experiment ends, we'll change this to just return back the userSegment
          // or even just the carousel object
          activeSegment: {
            type: 'carousel',
            segmentId: matchedCarousel.userSegmentId,
            order: []
          },
          products: gridProducts
        };
      });
    });
  }

  /**
   * Convert to the carousel product
   * @param products
   * @param favourites
   * @param user
   */
  public toGridProduct(products: Product[], favourites: Favourite[], user: User): GridProduct[] {
    return products.map((product) => {
      // Semi hacky way to cast as a GridProduct
      const carouselProduct = Object.assign(new GridProduct(), product);
      carouselProduct.relatedFavourite = (favourites || []).find((f) => f.product.id === product.id);
      carouselProduct.price = product.getPrice();
      carouselProduct.subscriptionPrice = product.getSubscriptionPrice();
      carouselProduct.threeMonthPrice = product.getPriceFor(3, 28);
      carouselProduct.lowResImageUrl = this.imageSizePipe.transform(product.imageUrls[0], 320);
      carouselProduct.previouslyPurchased = !!(user && (user.previouslyPurchasedProductIds || []).find((id) => id === product.id));
      carouselProduct.isGenericSubscription = (product.tags || []).indexOf('generic-subscriptions') > -1;

      return carouselProduct;
    });
  }

  /**
   * Get carousel products using
   * @param params
   */
  private getProductsForUserBySegment(params: GridProductInterface): Promise<{
    products: GridProduct[];
    activeSegment?: CarouselSegment;
  }> {
    const listType = params.listType;
    const isExpressCarousel = params.listType.type === 'expressOnly';
    const config = this.configService.getConfig() || ({} as Config);

    return Promise.all([
      this.productService.getAvailableProducts(params.shippingTo, params.orderIndex, params.discount),
      this.segmentService.getSegments(params.shippingTo).catch((e) => []),
      this.contentService.getContentSegments().catch((e) => []),
      this.favouritesService.getFavourites(params.shippingTo).catch((e) => []),
      this.segmentService.getBoostedProductExperiments(params.shippingTo).catch(() => [])
    ]).then(([availableProducts, availableSegments, contentSegments, favourites, promotedProductExperiments]) => {
      let products = availableProducts;
      const segments = availableSegments && availableSegments.carouselOrder;

      let activeSegment;

      // Filter out any skus which are gift vouchers, for now, we don't want to display them
      // ideally, this logic should be moved to the sku ordering service
      products = this.filterOutTypeExceptCheapest(products, ['physical_gift_voucher', 'digital_gift_voucher']);

      // Initial Sorting

      // Filter the products
      const obj = this.filterProductsAgainstListType(products, listType, segments);
      products = obj.products;
      activeSegment = obj.activeSegment;

      // Different exclusion rules, passed in via parameter
      products = this.excludeProductsBasedOnRules(products, params.exclusionRules);

      // Exclude any tag by segment request
      products = this.excludeTagsBasedOnSegment(products, contentSegments);

      // Exclude any by tag
      products = this.experimentTagExclude(products, listType, config.web_exclude_tags);

      // Exclude any by tag if under feature flag
      if (this.featuresService.getFeature('GENERIC_SUBSCRIPTIONS')) {
        products = this.excludeTagsBasedOnSubscriptions(products, listType);
      }

      // swap any products
      products = this.experimentProductSwap(products, availableProducts, config.SKU_SWAPPING_CAROUSEL);

      // If it's a bouquet link, we need to ensure it's available
      if (params.bouquet) {
        products = this.ensureAvailable(products, availableProducts, params.bouquet);
      }

      // Convert to more useful 'Carousel Products' with additional properties
      const gridProducts = this.toGridProduct(products, favourites, params.user);

      return this.boostedProductExperiments(gridProducts, promotedProductExperiments, availableSegments, listType).then((prods) => {
        let forCarousel = prods;

        // Do any sorting first, we want the bouquet= and ?availableForDeliveryOn to come last
        if (params.sortBy) {
          forCarousel = this.sortService.sortProducts(params.sortBy, forCarousel);
        }

        // If it's a bouquet URL, bring it to the front - this
        if (params.bouquet) {
          forCarousel = this.moveToFront(forCarousel, params.bouquet);
        }

        // If we want to see what's potentially available for a future date
        if (params.availableForDeliveryOn) {
          forCarousel = this.filterProductsForDeliverable(forCarousel, params.availableForDeliveryOn);
        }

        return {
          activeSegment,
          products: forCarousel
        };
      });
    });
  }

  /**
   * Filter out the products that have a type of "type", except the cheapest one
   * @param products
   * @param type
   * @returns
   */
  private filterOutTypeExceptCheapest(products: Product[], types: ProductType[]): Product[] {
    let giftVouchers = [];

    types.forEach((type) => {
      const giftVoucherByType = products.filter((p) => p.type === type).sort((a, b) => a.getPrice().price - b.getPrice().price);
      giftVouchers = giftVouchers.concat(giftVoucherByType);
    });

    return products.filter((p) => types.indexOf(p.type) < 0 || (giftVouchers.length && giftVouchers[0].id === p.id));
  }

  /**
   * Exclude subscriptions skus with tag if not at flowers-subscription grid
   * @param products
   */
  private excludeTagsBasedOnSubscriptions(products: Product[], listType: ListType): Product[] {
    const tag = this.countryService.siteConfigValue('product.carousel.subscription.tag');
    if (listType.value === tag) {
      return products;
    }

    return this.excludeProductsWithTags(products.slice(), ['exclude-subscriptions']);
  }

  /**
   * Exclude tags based on content segment exclusion
   * @param products
   * @param segments
   */
  private excludeTagsBasedOnSegment(products: Product[], segments: ContentSegment[]): Product[] {
    const productsCopy = products.slice();

    const toExclude = segments.reduce((acc, segment) => {
      acc.push(...segment.excluded_tags);
      return acc;
    }, []);

    return productsCopy.filter((product) => !toExclude.find((t) => (product.tags || []).indexOf(t) > -1));
  }

  /**
   * Exclude products based on certain "rules"
   * In the future, we may have more exclusion rules, but for now, it's all pretty simple.
   * @param products
   * @param exclusionRules
   */
  private excludeProductsBasedOnRules(products: Product[], exclusionRules: GridExclusionRule[] = []): Product[] {
    if (!exclusionRules || !exclusionRules.length) {
      return products;
    }
    let newList = products.slice();
    if (exclusionRules.indexOf(GridExclusionRule.ExcludeBundle) > -1) {
      newList = newList.filter((p) => !p.bundleOnly);
    }
    if (exclusionRules.indexOf(GridExclusionRule.ExcludeSubscriptions) > -1) {
      newList = newList.filter((p) => !p.subscriptionOnly);
    }
    return newList;
  }

  /**
   * Ensure that the product exists
   * @param products
   * @param availableProducts
   * @param bouquetSlug
   */
  private ensureAvailable(products: Product[], availableProducts: Product[], bouquetSlug: string): Product[] {
    // First, attempt to find in the original list
    const foundProduct = products.find((p) => (p.slug || '').toLowerCase() === bouquetSlug);

    if (foundProduct) {
      return products;
    }

    const newList = products.slice();

    // Then find it in all the products, and if so, push to array
    const product = availableProducts.find((p) => (p.slug || '').toLowerCase() === bouquetSlug);
    if (product) {
      newList.unshift(product);
    }
    return newList;
  }

  /**
   * Get the product from the original products, and move to the front
   * @param products
   * @param availableProducts
   * @param bouquetSlug
   */
  private moveToFront(products: GridProduct[], bouquetSlug: string): GridProduct[] {
    // Find it
    const product = products.find((p) => (p.slug || '').toLowerCase() === bouquetSlug);

    if (!product) {
      return products;
    }

    // Firstly, remove it from the list, then move it to the front
    const newList = products.slice().filter((p) => p.slug !== bouquetSlug);
    newList.unshift(product);

    return newList;
  }

  /**
   * Find the matching segment
   * @param segments
   * @param listType
   */
  private findMatchingSegment(segments: CarouselSegment[], listType: ListType): CarouselSegment {
    const type = listType.type.toLowerCase();
    const value = (listType.value || '').toLowerCase();
    const segs = segments || [];

    if (listType.value) {
      return segs.find((s) => type === s.type.toLowerCase() && value === s.value.toLowerCase());
    }
    return segs.find((s) => type === s.type.toLowerCase());
  }

  /**
   * Sort the products given an order object
   * @param orderObject
   * @param allProducts
   */
  private sortProductsAgainstSegment(segment: CarouselSegment, allProducts: Product[]): Product[] {
    if (!segment) {
      return allProducts;
    }
    // Find the associated product id for each
    const products: Product[] = [];
    segment.order
      .sort((a, b) => a.weight - b.weight)
      .forEach((order) => {
        const product = allProducts.find((p) => p.id === order.productId);
        if (product) {
          products.push(product);
        }
      });
    return products;
  }

  /**
   * Filter the product against a list type
   * @param products
   * @param listType
   */
  private filterProductsAgainstListType(
    products: Product[],
    listType: ListType,
    segments: CarouselSegment[]
  ): {
    products: Product[];
    activeSegment: CarouselSegment;
  } {
    let productsCopy = products.slice();

    // If it's tag only, attempt to find a matching "subnav" carousel...
    if (listType.type.toLowerCase() === 'tagonly') {
      let tagSegment = this.findMatchingSegment(segments, listType);

      if (tagSegment && tagSegment.order && tagSegment.order.length) {
        return {
          products: this.sortProductsAgainstSegment(tagSegment, productsCopy),
          activeSegment: tagSegment
        };
      }

      // If we can't find the matching segment, use the base and manually filter
      tagSegment = this.findMatchingSegment(segments, { type: 'base' });
      productsCopy = this.sortProductsAgainstSegment(tagSegment, productsCopy);
      return {
        products: productsCopy.filter((p) => (p.tags || []).indexOf(listType.value) > -1),
        activeSegment: tagSegment
      };
    }

    // Otherwise, only use the base carousel
    const segment = this.findMatchingSegment(segments, { type: 'base' });
    productsCopy = this.sortProductsAgainstSegment(segment, productsCopy);

    // If it's a tag, add them to the beginning, and others to the end
    if (listType.type === 'tag') {
      const matching = productsCopy.filter((p) => (p.tags || []).indexOf(listType.value) > -1);
      const notMatching = productsCopy.filter((p) => (p.tags || []).indexOf(listType.value) < 0);
      return {
        products: matching.concat(notMatching),
        activeSegment: segment
      };
    }

    // Likely the base carousel.
    return {
      products: productsCopy,
      activeSegment: segment
    };
  }

  /**
   * Exclude products
   * @param products
   * @param tags
   */
  private excludeProductsWithTags(products: Product[], tags: string[] = []): Product[] {
    return products.filter((product) => !(product.tags || []).find((tag) => tags.indexOf(tag) > -1));
  }

  /**
   * Filter products for only those that are deliverable From and To
   * @param products
   * @param deliverableOn
   */
  private filterProductsForDeliverable(products: GridProduct[], deliverableOn: dayjs.Dayjs): GridProduct[] {
    const timestamped = deliverableOn.unix();
    // Same as IsBefore/IsAfter but inclusive
    return products.filter((p) => p.deliverableFrom.unix() <= timestamped && p.deliverableTo.unix() >= timestamped);
  }

  /**
   * Sort the products by price
   * @param products
   */
  private sortProductsByPrice(products): Product[] {
    return products.sort((a, b) => a.getPrice().price - b.getPrice().price);
  }

  /**
   * Given an ordered list, sort the products against it, leaving the ones not in the list untouched
   * @param order
   * @param products
   */
  private sortAgainst(order: number[], products: any): any[] {
    const productsCopy = products.slice();
    productsCopy.sort((a, b) => {
      let aIndex = order.findIndex((productId) => productId === a.id);
      let bIndex = order.findIndex((productId) => productId === b.id);
      aIndex = aIndex === -1 ? 10000 : aIndex; // Ensure end if not found
      bIndex = bIndex === -1 ? 10000 : bIndex; // Ensure end if not found

      return aIndex < bIndex ? -1 : aIndex > bIndex ? 1 : 0;
    });
    return productsCopy;
  }

  /**
   * Given a list of 'empty' products (ie those with only an id), match with the list, filtering any we can't find
   * @param emptyProducts
   * @param products
   */
  private filterProductsByIds(productIds: number[], products: Product[]): Product[] {
    return productIds.map((id) => products.find((pr) => pr.id === id)).filter((p) => p);
  }

  /* ===============================
  Carousel Experiments
  =============================== */
  private getProductIdsIfInExperiment(carousel: Carousel): Promise<number[]> {
    // No experiments - so just return the product Ids
    if (!carousel.experiments || !carousel.experiments.length) {
      return Promise.resolve(carousel.productIds);
    }

    // Check if the experiment is already known to us, or we need to go and wait for it
    const promises = carousel.experiments.map((experiment) => {
      const alreadyKnown = this.experimentService.getExperiment(experiment.name);
      if (alreadyKnown) {
        return Promise.resolve(alreadyKnown);
      }
      this.experimentService.fireEvent(`carousel:${experiment.name}`);
      return this.experimentService.waitForExperiment(experiment.name, 100);
    });

    // Once all we've waited for all the experiments, check variant and return productIds to match
    return Promise.all(promises).then(() => {
      // An "old school" for loop means we can return easily
      for (let index = 0; index < carousel.experiments.length; index++) {
        const experiment = carousel.experiments[index];

        for (let i = 0; i < experiment.variants.length; i++) {
          const variant = experiment.variants[i];
          const inExperiment = this.experimentService.isActive(experiment.name, variant.variant);
          if (inExperiment) {
            return variant.productIds; // This could return back no products for the experiment
          }
        }
      }

      // We're not in any of the experiments, or the experiment we are in has no product IDs
      return carousel.productIds;
    });
  }

  /**
   * Fire events and put users into the product experiments
   * @param products
   * @param experiments
   */
  private boostedProductExperiments(
    gridProducts: GridProduct[],
    experiments: BoostedProductExperiment[],
    activeSegment: CarouselSegment,
    listType: ListType
  ): Promise<GridProduct[]> {
    let productsCopy = gridProducts.slice();

    // Only experiments which match the carousel the user is on is valid
    const validExperiments = experiments.filter(
      (experiment) =>
        !!experiment.carousels.find(
          (c) =>
            c.type === listType.type &&
            c.value === (listType.value || '') && // to check against both undefiend and empty string
            c.segment_ids.indexOf(activeSegment.segmentId) > -1
        )
    );

    if (!validExperiments || !validExperiments.length) {
      return Promise.resolve(gridProducts);
    }

    // Look for an experiment to roll out
    const experimentRollOut = validExperiments.find(
      (experiment) =>
        !(experiment.experiment || '').length && // No name
        (experiment.variants || []).length === 1 && // Only one variant
        !!experiment.variants.find((v) => v.variant === 0) // And that variant is 0
    );
    if (experimentRollOut) {
      const skusToBoost = experimentRollOut.variants[0].skus || [];
      if (skusToBoost.length) {
        productsCopy = this.sortAgainst(skusToBoost, productsCopy);
        productsCopy = productsCopy.map((p) => {
          p.isBoosted = skusToBoost.indexOf(p.id) > -1;
          return p;
        });
        return Promise.resolve(productsCopy);
      }
    }

    // Check if the experiment is already known to us, or we need to go and wait for it
    const promises = [];
    validExperiments.forEach((experiment) => {
      const alreadyKnown = this.experimentService.getExperiment(experiment.experiment);
      let promise;
      if (!alreadyKnown) {
        this.experimentService.fireEvent(`boosted:${experiment.experiment}`);
        promise = this.experimentService.waitForExperiment(experiment.experiment, 100);
      } else {
        promise = Promise.resolve(alreadyKnown);
      }
      promises.push(promise);
    });

    // Wait to see if we are in any of the experiments
    return Promise.all(promises).then(() => {
      // For each experiment we are actually in, re-order accordingly
      validExperiments.forEach((exp) => {
        (exp.variants || []).forEach((variant) => {
          const inExperiment = this.experimentService.isActive(exp.experiment, variant.variant);
          if (inExperiment) {
            productsCopy = this.sortAgainst(variant.skus, productsCopy);
            productsCopy = productsCopy.map((p) => {
              p.isBoosted = p.isBoosted || variant.skus.indexOf(p.id) > -1; // Could be previously promoted
              return p;
            });
          }
        });
      });

      return productsCopy;
    });
  }

  /*
   * Exclude tag
   * @param products
   * @param listType
   * @param config
   */
  private experimentTagExclude(products: Product[], listType: ListType, config: ExperimentTagExclude[]): Product[] {
    let productsCopy = products.slice();
    (config || []).forEach((conf) => {
      const experimentActive = this.experimentService.isActive(conf.experiment, conf.variant);
      const exc = (conf.excluded_carousels || []).find((n) => n.type === listType.type && n.tag === listType.value);
      if (experimentActive && !exc) {
        productsCopy = this.excludeProductsWithTags(productsCopy, conf.tags);
      }
    });
    return productsCopy;
  }

  /**
   * Swap the products if included in the experiment
   * @param products
   */
  private experimentProductSwap(products: Product[], allProducts: Product[], config: ExperimentSkuSwap[]): Product[] {
    let swapCriterias = [];
    const productsCopy = products.slice();

    const potentialSwaps = Array.isArray(config) ? config : [];

    // Find the data that matches the active experiment variant
    potentialSwaps.forEach((experimentOptions) => {
      const experiment = this.experimentService.getExperiment(experimentOptions.experiment);
      if (experiment && this.configService.getConfig().site === experimentOptions.site) {
        const experimentSwaps = experimentOptions.swap.filter((swapOptions) => swapOptions.variant === experiment.variant);
        swapCriterias = swapCriterias.concat(experimentSwaps);
      }
    });

    swapCriterias.forEach((item) => {
      // Check if replacement SKUs are in the "allProducts" array
      // Find the index at which to perform the swap
      const toSwap = item.replacements.reduce((acc, toSwapId) => {
        const found = allProducts.find((product) => product.id === toSwapId);
        if (found) {
          acc.push(found);
        }
        return acc;
      }, []);

      const index = products.findIndex((prod) => prod.id === item.original);
      // If we have things to swap, or we never wanted things to swap
      if (index > -1 && (toSwap.length || !item.replacements.length)) {
        productsCopy.splice(index, 1, ...toSwap);
      }
    });

    return productsCopy;
  }

  /**
   * Get the refreshed products
   * Sometimes we need to call the BE again to get the products that are in stock
   * (e.g. for a specific delivery date)
   * @param country
   * @param orderIndex
   * @param discount
   * @param deliveryDate as string format('YYYY-MM-DD')
   */
  getRefreshedProducts(country: Country, orderIndex: number, discount?: Discount, deliveryDate?: string): Promise<GridProduct[]> {
    this.productsAreRefreshed$.next(false);
    return this.productService.getAvailableProducts(country, orderIndex, discount, deliveryDate).then((products) => {
      const serverTime = this.configService.getConfig().serverTime;

      // filter products
      products = products.filter(
        (product) => product.isInStock && product.latestShippingOptionCutoff && product.latestShippingOptionCutoff.isAfter(serverTime)
      );

      // We only want to show the cheapest voucher, physical takes priority
      products = this.filterOutTypeExceptCheapest(products, ['physical_gift_voucher', 'digital_gift_voucher']);

      const gridProducts = this.toGridProduct(products, [], null);
      this.productsRefreshed$.next(gridProducts);
      this.productsAreRefreshed$.next(true);
      return gridProducts;
    });
  }

  /**
   * Set initial products
   * @param product
   */
  setInitialProducts(products): void {
    this.initialProducts = products;
    this.productsRefreshed$.next(products);
  }

  /**
   * Get initial products
   */
  getInitialProducts(): any {
    return this.initialProducts;
  }
}

interface ExperimentTagExclude {
  experiment: string;
  variant: number;
  tags: string[];
  excluded_carousels: {
    type: string;
    tag: string;
  }[];
}

interface ExperimentSkuSwap {
  experiment: string;
  site: string;
  swap: {
    variant: number;
    original: number;
    replacements: number[];
  }[];
}
