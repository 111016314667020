import { Injectable } from '@angular/core';
import { Product } from 'Shared/classes/product';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { FeaturesService } from 'Shared/services/features.service';
import { DiscountService } from 'Checkout/services/discount.service';
import { GiftingOptionsService } from 'Checkout/services/gifting-options.service';
import { Order, OrderType } from 'Shared/classes/order';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';
import { StateService } from 'Shared/services/state.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  constructor(
    private experimentsService: ExperimentsService,
    private featuresService: FeaturesService,
    private discountService: DiscountService,
    private giftingOptionsService: GiftingOptionsService,
    private optimizelyService: OptimizelyService,
    private stateService: StateService
  ) {}

  /**
   * Get checkout starting point
   * @param product
   * @returns string
   */
  getCheckoutStartingPoint(product: Product): string {
    const inGifting: boolean = this.isGiftingEnabled();
    if (!product?.tags?.includes('exclude-gifts') && inGifting) {
      this.giftingOptionsService.reset();
      return 'checkout.giftOptions';
    } else {
      return 'checkout.start';
    }
  }

  /**
   * Get checkout start point when editing an order
   * @param {Order} order
   * @param {boolean} hasAddons
   * @returns {string}
   */
  getCheckoutEditStartingPoint(order: Order): string {
    const isSubscription = this.featuresService.getFeature('SUBSCRIPTION_SHOP_NEW_UX') && order.type === OrderType.Subscription;

    if (isSubscription) {
      return 'checkout.editsubscription';
    }

    const hasExcludeGiftTag = order.product?.tags?.includes('exclude-gifts');
    if (this.isGiftingEnabled() && !hasExcludeGiftTag) {
      return 'checkout.giftOptions';
    } else {
      return 'checkout.editorder';
    }
  }

  /**
   * Check if gifting experiment is enabled
   * @returns {boolean}
   */
  isGiftingEnabled(): boolean {
    this.optimizelyService.decide('de_paid_greeting_cards');

    return this.featuresService.getFeature('GIFT_OPTIONS').active;
  }

  /**
   * Check if gifting bundle experiment is enabled
   * @returns {boolean}
   */
  isGiftingBundleEnabled(): boolean {
    return this.featuresService.getFeature('GIFT_OPTIONS').active && this.featuresService.getFeature('GIFT_OPTIONS').bundleUpsell;
  }

  /**
   * Check if bundle upsell is enabled
   * @returns {boolean}
   */
  public isGiftingBundleUpsellEnabled(): boolean {
    const product = this.giftingOptionsService.order?.product;
    return this.isGiftingBundleEnabled() && product?.pricing?.length > 1 && !product.subscriptionOnly && !product.bundleOnly;
  }

  /**
   * Check if multi code redemption is enabled
   * @returns {boolean}
   */
  public isMultiCodeRedemptionEnabled(): boolean {
    return (
      this.featuresService.getFeature('MULTI_CODE_REDEMPTION').active ||
      this.experimentsService.isActive('BLOOMON_NL_MULTI_CODE_REDEMPTION', 1) ||
      this.experimentsService.isActive('BLOOMON_BE_MULTI_CODE_REDEMPTION', 1)
    );
  }

  /**
   * Reset checkout via linked services
   * - Discount service resets discount code and gift vouchers
   */
  public resetCheckout(): void {
    this.discountService.reset();
    this.giftingOptionsService.reset();
  }

  /**
   * Check if stripe payment element experiment is enabled
   * Limited to checkout routes only
   * @returns {boolean}
   */
  public isInStripePaymentElementExperiment(): boolean {
    // Short term solution as all checkout routes contain 'checkout' in the state name
    const isCheckout = (this.stateService.getCurrent()?.name as string)?.includes('checkout') ?? false;
    return (
      this.featuresService.getFeature('STRIPE_PAYMENT_ELEMENT') &&
      this.experimentsService.isActive('STRIPE_PAYMENT_ELEMENT', 1) &&
      isCheckout
    );
  }

  /**
   * Check if joining rewards
   * @returns {boolean}
   */
  public isJoiningRewards(): boolean {
    return (
      this.featuresService.getFeature('JOINING_REWARDS').active &&
      (this.experimentsService.isActive('AUTO_OPT_IN_TO_REWARDS', 1) || this.experimentsService.isActive('AUTO_OPT_IN_TO_REWARDS', 2))
    );
  }
}
