import { Product } from 'Shared/classes/product';
import { Price } from 'Shared/classes/price';
import { Favourite } from 'Shared/classes/favourite';

export class GridProduct extends Product {
  isRecommended: boolean = false;
  isBoosted: boolean = false;
  relatedFavourite: Favourite;
  previouslyPurchased: boolean;
  subscriptionPrice: Price;
  threeMonthPrice: Price;
  lowResImageUrl: string;
  isGenericSubscription: boolean = false;
  shouldLoadLazily: boolean = false;
}

// REAT_YOURSELF_SUBS EXPERIMENT
interface UspList {
  heading: string;
  list: string[];
}

export enum ProductCardType {
  standard = 'standard',
  recentlyViewed = 'recentlyViewed',
  featuredProduct = 'featuredProduct'
}
