import { Injectable } from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { environment } from 'Environments/environment';
import { WindowRefService } from 'Shared/services/window.service';
import { Error } from 'Shared/classes/error';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { Card, CardType } from 'Shared/classes/card';
import { BankRedirect } from 'Shared/classes/bank-redirect';
import { Purchase } from 'Shared/classes/purchase';
import { t } from 'Shared/utils/translations';
import { User, UserService } from 'Shared/services/user.service';
import { LocationService } from 'Shared/services/location.service';
import { Country, CountryService } from 'Shared/services/country.service';
import { ConfigService } from 'Shared/services/config.service';
import { Order } from '../classes/order';
import { BankRedirectTypes } from '../classes/charges';
import { CheckoutService } from 'Checkout/services/checkout.service';
import {
  DefaultValuesOption,
  LayoutObject,
  PaymentIntent,
  PaymentIntentResult,
  Stripe,
  StripeElements,
  StripeElementsOptionsClientSecret,
  StripeError,
  StripePaymentElement,
  StripePaymentElementOptions
} from '@stripe/stripe-js';
import { PurchaseModelService } from 'Checkout/models/purchase-model.service';
import { StripeAccount } from 'Shared/models/config-model.service.typings';
import { BugsnagService } from './third-parties/bugsnag.service';
import { StateService } from './state.service';

interface LegacyStripeError {
  error: {
    message: string;
    code: string;
    type: string;
  };
}

interface ActivePaymentIntent {
  id: string;
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  public Stripe: any | undefined; // CamelCase to match Stripe's docs for ease
  public StripeConnect: any | undefined; // Stripe instance that uses the connected accounts to finalise a payment

  // New Stripe Payment Element var - cleaner single usage
  public stripe: Stripe | undefined;

  public checkoutStripeElements: StripeElements;

  private sdkPromiseInExperiment = false;
  private sdkPromise: Promise<any> | undefined;
  private environment;
  private activePaymentIntent: ActivePaymentIntent;
  private paymentIntentClientSecret: string | undefined;

  // Stripe Payment Element layout options

  // TODO possibly read signals to know if element should be collapsed by default
  private stripeElementLayout: LayoutObject = {
    type: 'accordion',
    radios: false,
    spacedAccordionItems: true
  };

  private stripeElementPaymentOrder: CardType[] = ['bancontact', 'ideal', 'apple_pay', 'google_pay', 'card', 'sofort'];

  constructor(
    private domUtils: DomUtilsService,
    private windowRef: WindowRefService,
    private analyticsService: AnalyticsService,
    private locationService: LocationService,
    private countryService: CountryService,
    private configService: ConfigService,
    private checkoutService: CheckoutService,
    private purchaseModelService: PurchaseModelService,
    private userService: UserService,
    private bugsnagService: BugsnagService,
    private stateService: StateService
  ) {
    this.environment = environment;
  }

  /**
   * Handle any stripe messages into a more 'Bloom & Wild' friendly way
   * @param res
   * @returns {Error}
   */
  handleStripeErrorMessages({ code, type }: StripeError): Error {
    const error = new Error({
      message: t('js.payment.error.stripe.generic.message'),
      title: t('js.payment.error.stripe.generic.title'),
      code: code ?? undefined,
      kind: type ?? undefined
    });

    error.meta = {
      source: 'stripeErrors',
      code
    };
    return error;
  }

  /**
   * Handle any legacy stripe messages into a more 'Bloom & Wild' friendly way
   * @param res
   * @returns {Error}
   */
  handleLegacyStripeErrorMessages(res: LegacyStripeError): Error {
    const { code, type } = res.error ?? {};

    const error = new Error({
      message: t('js.payment.error.stripe.generic.message'),
      title: t('js.payment.error.stripe.generic.title'),
      code: code ?? undefined,
      kind: type ?? undefined
    });

    error.meta = {
      source: 'stripeErrors',
      code: res.error ? res.error.code : undefined
    };
    return error;
  }

  /**
   * Load the SDK
   * @returns {Promise<void>}
   * NOTE: Deliberately messy at the moment due to legacy Stripe loading alongside Stripe Payment Element loading
   * In the future this will be tidied up with the rollout of SPE i.e No need to load Stripe and StripeConnect together
   */
  loadSDK(): Promise<void> {
    const shouldLoadNewStripeElement = this.checkoutService.isInStripePaymentElementExperiment();

    if (shouldLoadNewStripeElement !== this.sdkPromiseInExperiment || this.sdkPromise === undefined) {
      this.sdkPromiseInExperiment = shouldLoadNewStripeElement;
      this.sdkPromise = this.initStripe(shouldLoadNewStripeElement);
    }

    return this.sdkPromise;
  }

  /**
   * Handle the payment
   * @param paymentOption
   * @param paymentSecret
   */
  handlePayment(paymentSecret: string, paymentOption: Card): Promise<any> {
    const args = [
      paymentSecret,
      {
        payment_method: paymentOption.token
      },
      {
        handleActions: true
      }
    ];

    // stripe sdk confirm functions
    const stripeFunction = paymentOption.kind === 'sepa_debit' ? 'confirmSepaDebitPayment' : 'confirmCardPayment';

    return this.StripeConnect[stripeFunction](...args).then((result) => {
      if (!result.error) {
        return Promise.resolve(result);
      }
      const error = this.handleStripeErrorMessages(result);
      this.analyticsService.trackError(error);
      return Promise.reject(error);
    });
  }

  /**
   * Handle the card setup intent for future use
   * Used to add card from my account
   * @param setupIntentSecret
   * @param card
   */
  handleCardSetup(setupIntentSecret: string, card: Card): Promise<any> {
    return this.Stripe.confirmCardSetup(setupIntentSecret, {
      payment_method: card.token
    }).then((result) => {
      if (!result.error) {
        return Promise.resolve(result);
      }

      const error = this.handleLegacyStripeErrorMessages(result);

      this.analyticsService.trackError(error);
      return Promise.reject(error);
    });
  }

  /**
   * Create a stripe token given a stripe card object
   * @param card
   */
  createToken(card: any, orderShippingCountry?: Country): Promise<any> {
    return this.Stripe.createToken(card, orderShippingCountry).then((result) => {
      if (!result.error) {
        return Promise.resolve(result.token);
      }

      const error = this.handleStripeErrorMessages(result);
      this.analyticsService.trackError(error);
      return Promise.reject(error);
    });
  }

  /**
   * Get the payment intent details
   * Used by Payment result in my account, not in the main flow
   * @param paymentIntent
   */
  getPaymentIntent(paymentIntent: string): Promise<any> {
    return this.Stripe.retrievePaymentIntent(paymentIntent).then((result) => {
      const isSuccess = result && result.paymentIntent && result.paymentIntent.status === 'succeeded';

      if (isSuccess) {
        return Promise.resolve(result);
      }

      if (!result.error) {
        result.error = {
          code: 'paymentIntentFailed',
          message: 'Payment Intent Failed',
          type: 'paymentIntent'
        };
      }

      const error = this.handleLegacyStripeErrorMessages(result);

      this.analyticsService.trackError(error);
      return Promise.reject(error);
    });
  }

  /**
   * Initiates the iDeal redirect
   */
  confirmBankRedirectPayment(res: string, paymentMethod: CardType, paymentDetails: BankRedirect['paymentDetails']): Promise<any> {
    // stripe sdk redirect methods
    const stripeRedirects = {
      ideal: 'confirmIdealPayment',
      bancontact: 'confirmBancontactPayment',
      sofort: 'confirmSofortPayment'
    };

    return this.StripeConnect[stripeRedirects[paymentMethod]](res, paymentDetails, {
      handleActions: true
    }).then((result) => {
      if (!result.error) {
        return Promise.resolve(result);
      }

      const error = this.handleLegacyStripeErrorMessages(result);

      this.analyticsService.trackError(error);
      return Promise.reject(error);
    });
  }

  /**
   * Returns the redirect url for a bank redirect payment method
   */
  getRedirectUrl(user: User, purchase: Purchase, paymentMethod: CardType): string {
    const host = this.locationService.getHostWithSubfolder();
    let returnURL = `https://${host}/restore.html#purchaseId=${purchase?.id}&purchaseToken=${
      purchase?.token
    }&bankRedirect=${paymentMethod}&userEmail=${user?.email?.address}&userFullName=${encodeURIComponent(user?.fullName)}`;

    if (user?.token) {
      returnURL += `&userToken=${user.token}`;
    }

    return returnURL;
  }

  /**
   * Returns the redirect url using order for a bank redirect payment method
   * @param {User} user
   * @param {Order} order
   * @param {BankRedirectTypes} type
   * @returns {string}
   */
  getOrderRedirectUrl(user: User, order: Order, type: BankRedirectTypes): string {
    const host = this.locationService.getHostWithSubfolder();

    let returnURL = `https://${host}/restore.html#orderId=${order?.id}&bankRedirect=${type}&userEmail=${
      user?.email?.address
    }&userFullName=${encodeURIComponent(user?.fullName)}`;

    if (user?.token) {
      returnURL += `&userToken=${user.token}`;
    }

    const { activeWithFailedPayment, pausedDueToFailedPayment } = order.subscription?.failedPaymentsData ?? {};
    if (activeWithFailedPayment === true || pausedDueToFailedPayment === true) {
      returnURL += '&hasFailedPayment=true';
    }

    if (order.stateIs('paused')) {
      returnURL += '&isPausedOrder=true';
    }

    return returnURL;
  }

  /**
   * Generate the Stripe Payment Element
   * @param purchase
   * @param options
   * @param storeCard
   */
  public async generateStripePaymentElement(
    purchase: Purchase,
    options: StripeElementsOptionsClientSecret,
    storeCard: boolean = false
  ): Promise<StripePaymentElement> {
    const intentResponse = await this.purchaseModelService.getStripePaymentIntent(purchase, storeCard);

    this.activePaymentIntent = { id: intentResponse.id, status: intentResponse.status };

    const clientSecret = intentResponse.client_secret;
    this.paymentIntentClientSecret = clientSecret;
    this.checkoutStripeElements = this.stripe.elements({ ...options, clientSecret });

    const user = this.userService.getUser();
    const stripeElementDefaultValues: DefaultValuesOption = {
      billingDetails: {
        name: user.fullName ?? '',
        email: user.email?.address ?? ''
      }
    };

    const paymentElementOptions: StripePaymentElementOptions = {
      layout: this.stripeElementLayout,
      paymentMethodOrder: this.stripeElementPaymentOrder,
      defaultValues: stripeElementDefaultValues
    };

    const paymentElement: StripePaymentElement = this.checkoutStripeElements.create('payment', paymentElementOptions);
    return Promise.resolve(paymentElement);
  }

  /**
   * Confirm the Saved Method Payment
   * @param intent
   * @param paymentMethod
   * @param purchase
   * @returns {Promise<PaymentIntent | never>}
   */
  public confirmSavedMethodPayment(purchase: Purchase, methodDetails: Card): Promise<PaymentIntent> {
    if (this.paymentIntentClientSecret === undefined) {
      return Promise.reject('Client secret not available; unable to confirm payment');
    }

    const user = this.userService.getUser();

    // Confirm the PaymentIntent using the details collected by the Payment Element
    return this.handleConfirmResult(
      this.stripe.confirmPayment({
        clientSecret: this.paymentIntentClientSecret,
        confirmParams: {
          return_url: this.getRedirectUrl(user, purchase, methodDetails.kind),
          payment_method: methodDetails.token
        },
        redirect: 'if_required'
      })
    );
  }

  /**
   * Confirm the Stripe Payment Element Payment
   * @param intent
   * @param paymentMethod
   * @param purchase
   * @returns {Promise<PaymentIntent | never>}
   */
  public confirmStripePaymentElementPayment(paymentMethod: CardType, purchase: Purchase): Promise<PaymentIntent | never> {
    const elements = this.checkoutStripeElements;
    const user = this.userService.getUser();

    // Confirm the PaymentIntent using the details collected by the Payment Element
    return this.handleConfirmResult(
      this.stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: this.getRedirectUrl(user, purchase, paymentMethod)
        },
        redirect: 'if_required'
      })
    );
  }

  /**
   * Confirm the Stripe Payment Element Payment
   * @param intent
   * @param paymentMethod
   * @param purchase
   * @returns {Promise<PaymentIntent | never>}
   */
  public handleConfirmResult(confirmResult: Promise<PaymentIntentResult>): Promise<PaymentIntent> {
    return confirmResult
      .then(({ paymentIntent, error }: PaymentIntentResult): Promise<PaymentIntent> => {
        if (error !== undefined) {
          const processedError: Error = this.handleStripeErrorMessages(error);
          this.analyticsService.trackError(processedError);
          return Promise.reject(processedError);
        } else {
          return Promise.resolve(paymentIntent);
        }
      })
      .catch((result: StripeError): Promise<PaymentIntent> => {
        const error: Error = this.handleStripeErrorMessages(result);
        this.analyticsService.trackError(error);
        return Promise.reject(error);
      });
  }

  public updatePaymentMethods(purchase: Purchase, saveForFutureUse: boolean, paymentMethod: CardType): Promise<void> {
    return this.purchaseModelService
      .updateStripePaymentIntent(purchase, this.activePaymentIntent.id, saveForFutureUse, paymentMethod)
      .then((): Promise<{ error?: { message: string; status?: string } }> => this.checkoutStripeElements.fetchUpdates())
      .then((errorData): void => {
        if (errorData.error !== undefined) {
          this.bugsnagService.logEvent(
            new Error({
              title: 'Failed to fetch Stripe updates',
              message: errorData.error.message,
              code: 'stripeUpdatesFail'
            })
          );
        }
      })
      .catch((error: Error): void => {
        this.bugsnagService.logEvent(error);
      });
  }

  /**
   * Init stripe script and instance
   * @returns {Promise<void>}
   */
  private initStripe(shouldLoadNewStripeElement: boolean): Promise<void> {
    const shippingCountry = this.countryService.forShipping;
    const script = 'https://js.stripe.com/v3/';

    return this.domUtils
      .loadScript(script, 'stripe')
      .then((): Promise<StripeAccount> => this.configService.getStripeAccount(shippingCountry))
      .then((stripeConfig: StripeAccount): void => {
        // ideally tidy up in the future
        const stripeFn = this.windowRef.nativeWindow.Stripe;
        const options = stripeConfig?.stripeAccountId ? { stripeAccount: stripeConfig.stripeAccountId } : {};
        if (shouldLoadNewStripeElement) {
          this.Stripe = undefined;
          this.StripeConnect = undefined;
          const locale = this.configService.getConfig().stripeLocale;
          this.stripe = stripeFn(stripeConfig.stripeClientKey, { locale: locale, ...options }); // NEW STRIPE PAYMENT ELEMENT
        } else {
          this.stripe = undefined;
          this.Stripe = stripeFn(stripeConfig.stripeClientKey); // OLD STRIPE ELEMENT
          // Initialising the stripe instance with connected accounts
          this.StripeConnect = stripeFn(stripeConfig.stripeClientKey, options);
        }
      });
  }
}
