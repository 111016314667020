import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { EmailService, Email } from 'Shared/services/email.service';
import { User, UserService } from 'Shared/services/user.service';
import { Order } from 'Shared/classes/order';
import { PurchaseService } from 'Checkout/services/purchase.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { LocationService } from 'Shared/services/location.service';
import { HeapService } from 'Shared/services/third-parties/heap.service';
import { Subscription } from 'rxjs';
import { FeaturesService } from 'Shared/services/features.service';
import { BwForm, BwFormControl, BwFormDefintion } from 'Shared/classes/bw-form';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EmailPreference } from 'Shared/classes/email';
import { ValidateUserDetails } from 'Checkout/validators/validateUserDetails';
import { AuthTypes, ACCOUNT_OFFERINGS, AccountOffering } from 'Shared/components/modals/auth-modal/auth';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { RewardsInfoModalComponent } from 'Checkout/components/rewards-info-modal/rewards-info-modal.component';
import { LoyaltyService } from 'Shared/services/loyalty.service';
import { ModalService } from 'Shared/services/modal.service';

type UserDetailsControls = {
  email: BwFormControl<string>;
  consent?: BwFormControl<EmailPreference>;
  fullName: BwFormControl<string>;
};

/* bw:view-encapsulation */
@Component({
  selector: 'bw-details-user-guest',
  templateUrl: './details-user-guest.component.html'
})
export class DetailsUserGuestComponent extends BwForm<UserDetailsControls> implements OnInit, OnDestroy {
  @Input() preferredName: string;
  @Input() preferredEmail: string;
  @Input() order: Order;
  @Input() checkoutOrigin: boolean = false;
  @Input() showTandC: boolean = false;

  @Input() toRegister: boolean = false;
  @Input() validateForm: boolean = false;

  @Output() userChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() back: EventEmitter<AuthTypes> = new EventEmitter<AuthTypes>();
  @Output() didSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() guestChanged: EventEmitter<{ email: string; consent: EmailPreference; fullName: string }> = new EventEmitter<{
    email: string;
    consent: EmailPreference;
    fullName: string;
  }>();

  user: User = new User('');
  showSofterGDPRMessage: boolean;
  emailSubscribe: Subscription;
  shouldAutoFocus: boolean = false;
  identifiedUser: string;
  loading: boolean;
  isEmailSent: boolean = false;
  accountOfferings: AccountOffering[];
  loyaltyOrderPoints: number;

  /**
   * Constructor
   * @param emailService
   * @param windowRef
   */
  constructor(
    private emailService: EmailService,
    private purchaseService: PurchaseService,
    private analyticsService: AnalyticsService,
    private locationService: LocationService,
    private heapService: HeapService,
    private featuresService: FeaturesService,
    private userService: UserService,
    private experimentService: ExperimentsService,
    private loyaltyService: LoyaltyService,
    private modalService: ModalService
  ) {
    super();
  }

  /**
   * Check if joining rewards is on login
   */
  get isJoiningRewardsOnLogin(): boolean {
    return (
      this.featuresService.getFeature('JOINING_REWARDS').active &&
      (this.experimentService.isActive('AUTO_OPT_IN_TO_REWARDS', 1) || this.experimentService.isActive('AUTO_OPT_IN_TO_REWARDS', 2))
    );
  }

  /**
   * Build the form
   */
  buildForm(): BwFormDefintion<UserDetailsControls> {
    const nameValidations = [Validators.required];
    if (this.featuresService.getFeature('REQUIRE_SPACE_IN_USER_NAME')) {
      nameValidations.push(ValidateUserDetails.fullNameRequired);
    }

    return {
      email: new FormControl(this.preferredEmail ?? '', [Validators.required, Validators.email]),
      fullName: new FormControl(this.preferredName ?? '', nameValidations),
      consent: new FormControl(undefined)
    };
  }

  /**
   * BwForm required implementation
   */
  getObject(): void {}

  /**
   * BwForm required implementation
   */
  setObject(): void {}

  /**
   * Update the user
   */
  onNameBlur(): void {
    const nameControl = this.get('fullName');
    if (nameControl.valid) {
      this.user.fullName = nameControl.value;
      this.userChanged.emit(this.user);
    }
  }

  /**
   * On continue
   */
  onSubmit(submitForm?: boolean): Promise<void> {
    this.markAsSubmitted();
    const hasAccount = this.user.email.hasRegistered ?? false;

    if (this.toRegister) {
      this.analyticsService.trackInHeap('clickContinueAsGuest', {
        hasAccount,
        verificaitonDisplayed: true,
        verificaitonRequested: this.isEmailSent ?? false
      });
    } else {
      const guestOptInSelection =
        (document.querySelector('bw-form-radio-input .is-selected .radio-input__label-copy') as HTMLElement)?.innerText ?? '';
      const email = this.user.email;
      const accountStatus = email.hasRegistered ? 'registered' : email.hasOrdered ? 'guest' : 'none';
      this.analyticsService.trackInHeap('submitContinueAsGuest', {
        hasAccount,
        guestOptInSelection,
        accountStatus
      });
    }

    this.toRegister = false;
    this.didSubmit.emit({ guestForm: this.formGroup, submitForm });

    // Track Order
    const order = this.order;
    const purchase = this.purchaseService.getPurchase();
    const listType = this.locationService.getListType();
    this.analyticsService.trackInHeap('orderDetails', { purchase, order, listType });

    // Set the email preference if consent is enabled
    return this.setEmailPreference();
  }

  /**
   * Updates the email with Api Data
   * @param emailAddress
   */
  onEmailChange(emailAddress: string): Promise<Email> {
    const userEmail = new Email(emailAddress);
    return this.emailService.get(userEmail).then((email): Email => {
      this.user.setEmail(email.clone());

      this.userChanged.emit(this.user);
      this.heapService.setGlobalEventProperty({
        hasRegistered: this.user.email.hasRegistered
      });

      this.showSofterGDPRMessage = this.featuresService.getFeature('GDPR').softerGDPR;
      this.loading = false;
      return email;
    });
  }

  /**
   * Set up which account offerings to show
   * @returns {AccountOffering[]}
   */
  setAccountOfferings(): AccountOffering[] {
    const offerings = this.featuresService.getFeature('AUTH_REGISTER_OFFERINGS');

    return ACCOUNT_OFFERINGS.map((o): AccountOffering => {
      o.show = offerings[o.key];
      return o;
    });
  }

  /**
   * On component init
   */
  ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.loading = true;

    this.calculateLoyaltyPoints();

    if (this.preferredName) {
      this.user.fullName = this.preferredName;
    }

    if (this.get('fullName').valid) {
      this.get('fullName').markAsTouched();
    }

    this.identifiedUser = this.user.getNameParts().first;
    if (this.featuresService.getFeature('REQUIRE_SPACE_IN_USER_NAME')) {
      this.get('fullName').setValue('');
    }

    return Promise.resolve()
      .then((): Promise<Email | void> => {
        if (this.preferredEmail) {
          this.get('email').markAsTouched();
          return this.onEmailChange(this.preferredEmail);
        }

        return Promise.resolve();
      })
      .then((): void => {
        if (this.featuresService.getFeature('GDPR').softerGDPR) {
          this.showSofterGDPRMessage = true;
        }

        this.accountOfferings = this.setAccountOfferings();
        // Calculate loyalty points
        this.calculateLoyaltyPoints();

        this.emailSubscribe = this.get('email')
          .valueChanges.pipe(debounceTime(250), distinctUntilChanged())
          .subscribe((value): Promise<void> => {
            if (this.get('email').valid) {
              return this.onEmailChange(value).then((): void => {
                this.loading = false;
              });
            }
          });

        this.formGroup.valueChanges.subscribe((value: { email: string; consent: EmailPreference; fullName: string }): void => {
          this.guestChanged.emit(value);

          setTimeout((): void => {
            if (this.validateForm && this.invalid) {
              this.markAllAsTouched();
              this.updateValueAndValidity();
            }
          }, 0);
        });
      })
      .finally(() => {
        this.loading = false;
      })
      .catch((): void => {});
  }

  /** On component destroy */
  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.emailSubscribe) {
      this.emailSubscribe.unsubscribe();
    }
  }

  /**
   * Show the login form
   */
  login(): void {
    this.analyticsService.trackInHeap('loginAsGuest', {
      hasAccount: this.user.email.hasRegistered ?? false
    });
    this.back.emit('login');
  }

  activateAccount(): Promise<void> {
    this.loading = true;
    const email = new Email(this.preferredEmail);

    return this.setEmailPreference()
      .then((): Promise<User> => this.userService.requestActivateAccount(email))
      .then((): void => {
        this.loading = false;
        const applyFullName = this.featuresService.getFeature('REQUIRE_SPACE_IN_USER_NAME');
        this.get('fullName').setValue(applyFullName && this.user.fullName ? this.user.fullName : '');
        const el = document.getElementById('emailField');
        el.scrollIntoView();
        this.isEmailSent = true;
      })
      .catch((): void => {
        this.loading = false;
        this.isEmailSent = true;
      });
  }

  /**
   * Open rewards info modal
   * @returns {Promise<void>}
   */
  openRewardsInfoModal(): Promise<void> {
    return this.modalService
      .show(RewardsInfoModalComponent, {
        trackingKey: 'rewards-info-modal',
        class: 'modal-sm rewards-info-modal'
      })
      .catch((): void => {});
  }

  /**
   * Calculate loyalty points
   */
  private calculateLoyaltyPoints(): void {
    const order = this.order;
    const price = Order.calculateOrderTotal({ ...order });
    this.loyaltyOrderPoints = this.loyaltyService.calculateTotalOrderLoyaltyPoints(price);
  }

  /**
   * @description Set email preference if consent is enabled
   * @returns {Promise<void>}
   */
  private setEmailPreference(): Promise<void> {
    // Submit the consent
    const consentControl = this.get('consent');
    if (consentControl?.enabled && consentControl['submitConsent']) {
      return this.get('consent')['submitConsent']();
    }

    return Promise.resolve();
  }
}
