/*
  ====================================

  FOR MAXIMUM PERFORMANCE:
  Please wrap every command in this.untilIdleService.queue(() = { });
  This will ensure that the analytics runs when the browser is idle, hopefully improving performance
  You are best doing this per command, rather than per section, as ideally each command should take less than 50ms to complete

  ====================================
*/
import { Injectable } from '@angular/core';
import { analyticsKeys } from 'Environments/analytics';
import { UserService } from 'Shared/services/user.service';
import { AppboyService } from 'Shared/services/third-parties/appboy.service';
import { BranchService } from 'Shared/services/third-parties/branch.service';
import { GtmService } from 'Shared/services/third-parties/gtm.service';
import { WindowRefService } from 'Shared/services/window.service';
import { Product } from 'Shared/classes/product';
import { Purchase } from 'Shared/classes/purchase';
import { Order } from 'Shared/classes/order';
import { User } from 'Shared/classes/user';
import { Country } from 'Shared/classes/country';
import { GaService, GaProductImpressions } from 'Shared/services/third-parties/ga.service';
import { HeapService } from 'Shared/services/third-parties/heap.service';
import { Error } from 'Shared/classes/error';
import { BugsnagService } from 'Shared/services/third-parties/bugsnag.service';
import { LocalStorageService } from 'Shared/services/local-storage.service';
import { StateService } from 'Shared/services/state.service';
import { HotjarService } from 'Shared/services/third-parties/hotjar.service';
import { UntilIdleService } from 'Shared/services/until-idle.service';
import { ConfigService } from 'Shared/services/config.service';
import { FacebookMarketingService } from 'Shared/services/third-parties/facebook-marketing.service';
import { TiktokMarketingService } from 'Shared/services/third-parties/tiktok-marketing.service';
import { SnapchatService } from 'Shared/services/third-parties/snapchat.service';
import { ZendeskWidgetService } from 'Shared/services/third-parties/zendesk-widget.service';
import { ZyperService } from 'Shared/services/third-parties/zyper.service';
import { TrustedShopsService } from 'Shared/services/third-parties/trusted-shops.service';
import { RakutenService } from 'Shared/services/third-parties/rakuten.service';
import { TvsquaredService } from 'Shared/services/third-parties/tvsquared.service';
import { DrtvService } from './third-parties/drtv.service';
import { PinterestService } from 'Shared/services/third-parties/pinterest.service';
import { QuoraService } from 'Shared/services/third-parties/quora.service';
import { BingService } from 'Shared/services/third-parties/bing.service';
import { AppsFlyerService } from 'Shared/services/third-parties/appsflyer.service';
import * as dayjs from 'dayjs';
import { Addon } from 'Shared/classes/addon';
import { InflcrService } from 'Shared/services/third-parties/inflcr.service';
import { GtagService } from './third-parties/gtag.service';
import { GtagServiceGA4, FormName, FieldName } from 'Shared/services/third-parties/gtag-ga4.service';
import { CurrencyCode } from 'Shared/classes/price';
import { DrtvData } from 'Shared/services/third-parties/drtv.service';
import { PartnerizeService } from './third-parties/partnerize.service';
import { LocationService } from 'Shared/services/location.service';
import { NavAnalyticsInfo } from 'Shared/classes/mega-nav-item';
import { ExperimentOptions } from '../classes/experiment';
import { ViewportDetectionService, IActiveViewports } from 'Shared/services/viewport-detection.service';
import { BehaviorSubject } from 'rxjs';

type AnalyticsBasicEvent = keyof typeof analyticsKeys;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  debug: boolean = true;
  keys: any;
  isCustomerDelight: boolean = false;
  isLfa: boolean = false;
  isFirstPageView = true;
  window: any;
  dimensions: any = {};

  tracked: any = {};
  subsPromoCardIndex: any;

  viewportSizeIs$: BehaviorSubject<IActiveViewports> = this.viewportDetectionService.viewportSizeIs$;

  constructor(
    private userService: UserService,
    private appboyService: AppboyService,
    private branchService: BranchService,
    private gaService: GaService,
    private gtmService: GtmService,
    private gtagServiceGA4: GtagServiceGA4,
    private windowRef: WindowRefService,
    private heapService: HeapService,
    private bugSnagService: BugsnagService,
    private localStorageService: LocalStorageService,
    private stateService: StateService,
    private hotjarService: HotjarService,
    private untilIdleService: UntilIdleService,
    private quoraService: QuoraService,
    private facebookMarketingService: FacebookMarketingService,
    private tiktokMarketingService: TiktokMarketingService,
    private snapchatService: SnapchatService,
    private zendeskService: ZendeskWidgetService,
    private zyperService: ZyperService,
    private configService: ConfigService,
    private trustedShopsService: TrustedShopsService,
    private rakutenService: RakutenService,
    private tvsquaredService: TvsquaredService,
    private drtvService: DrtvService,
    private pinterestService: PinterestService,
    private bingService: BingService,
    private appsflyerService: AppsFlyerService,
    private inflcrService: InflcrService,
    private gtagService: GtagService,
    private partnerizeService: PartnerizeService,
    private locationService: LocationService,
    private viewportDetectionService: ViewportDetectionService
  ) {
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;

    if (this.debug) {
      this.window.document.querySelector('body').setAttribute('bw-analytics', 'debug');
      window['analyticsDebug'] = true;
    }

    this.keys = analyticsKeys;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<analytics>', ...args);
    }
  }

  /**
   * Set is customer delight
   */
  setIsCustomerDelight(): void {
    this.identify(new User());
    this.window.isCustomerDelight = true;
    this.window.bucketNumber = -1;
    this.isCustomerDelight = true;
    this.appboyService.disable();
    this.setDimension('isCustomerDelight', 1);
    this.setDimension('bucketNumner', -1);
    this.localStorageService.set('isCustomerDelight', 1);
    this.gtmService.addToDataLayer({ isCustomerDelight: true });
    console.warn('==== NOW SET TO CUSTOMER DELIGHT ====');
    console.warn(`Use localStorage.removeItem('BW.isCustomerDelight'); to clear`);
    console.warn('=====================================');
  }

  /**
   * Init
   */
  init(): void {
    // By default, everyone is logged out and hasn't registered
    this.gaService.setDimension('loggedIn', 'false');
    this.gaService.setDimension('userRegistered', 'false');

    this.subscribeToUserChanges();
  }

  /**
   * Subscribe to any user changes
   */
  subscribeToUserChanges(): void {
    this.userService.user$.subscribe(() => {
      const user = this.userService.getUser();
      this.identify(user);
    });
  }

  /**
   * Set the current currency for GA Service
   */
  setCurrency(currency: CurrencyCode): void {
    this.untilIdleService.queue(() => {
      this.gaService.setCurrency(currency);
    }, 2);
  }

  /**
   * Identify
   * @param user
   */
  identify(user: User): void {
    if (this.configService.getConfig().heapEnabled) {
      this.untilIdleService.queue(() => {
        this.heapService.identify(user);
      });
    }

    this.untilIdleService.queue(() => {
      this.appsflyerService.identify(user);
    });

    this.untilIdleService.queue(() => {
      this.gaService.identify(user);
    });
    this.untilIdleService.queue(() => {
      this.appboyService.identify(user);
    });
    this.untilIdleService.queue(() => {
      this.facebookMarketingService.identify(user);
      this.facebookMarketingService.setUserProperties({
        userRegistered: user.isLoggedIn() || user.email.hasRegistered || user.email.hasOrdered,
        isLoggedIn: user.isLoggedIn()
      });
    });

    if (this.configService.getConfig().tiktokPixelEnabled) {
      this.untilIdleService.queue(() => {
        this.tiktokMarketingService.identify(user);
      });
    }

    if (this.configService.getConfig().snapchatEnabled) {
      this.untilIdleService.queue(() => {
        this.snapchatService.identify(user);
      });
    }

    this.untilIdleService.queue(() => {
      this.branchService.identify(user);
    });
    this.untilIdleService.queue(() => {
      this.gtmService.identify(user);
    });
    this.untilIdleService.queue(() => {
      this.bugSnagService.identify(user);
    });

    if (this.configService.getConfig().tvSquaredEnabled) {
      this.untilIdleService.queue(() => {
        this.tvsquaredService.identify(user);
      });
    }

    if (this.configService.getConfig().hotjarEnabled) {
      this.untilIdleService.queue(() => {
        this.hotjarService.identify(user);
      });
    }
  }

  /**
   * Get the property (from dot notation) from an object
   * @param obj
   * @param path eg data.something.here.woop
   */
  getProperty(obj: any, path: string): string {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  /**
   * Track Errors
   * @param err
   * @param type
   */
  trackError(err: Error): void {
    this.untilIdleService.queue(() => {
      this.gtmService.trackError(err);
    }, 1);

    this.untilIdleService.queue(() => {
      this.gaService.trackError(err);
    }, 1);
  }

  /**
   * Set dimension
   * @param key
   * @param value
   */
  setDimension(key: string, value: any): void {
    this.dimensions[key] = value;
    const obj = {};
    obj[key] = `${value}`;

    if (['carouselSegment', 'contentSegment'].indexOf(key) > -1) {
      this.untilIdleService.queue(() => {
        this.facebookMarketingService.setUserProperties(obj);
      }, 1);
    }

    this.untilIdleService.queue(() => {
      this.bugSnagService.setDimension(key, `${value}`);
    }, 1);

    this.untilIdleService.queue(() => {
      this.gaService.setDimension(key, `${value}`);
    }, 1);
    if (this.configService.getConfig().heapEnabled) {
      this.untilIdleService.queue(() => {
        this.heapService.setEventProperties(obj);
      }, 1);
    }

    if (this.configService.getConfig().hotjarEnabled) {
      this.untilIdleService.queue(() => {
        this.hotjarService.setDimension(key, `${value}`);
      }, 1);
    }
  }

  trackInHeap(key: string, obj: any = {}): void {
    if (this.configService.getConfig().heapEnabled) {
      this.untilIdleService.queue(() => {
        this.heapService.logAdvancedEvent(key, obj);
      }, 1);
    }
  }

  /**
   * Track a custom timing
   * @param name
   * @param value
   */
  trackTiming(name: string, value: number): void {
    this.untilIdleService.queue(() => {
      this.gaService.trackSpeed('timings:custom', name, value);
    }, 1);
  }

  /**
   * Add to data layer
   * @param obj
   */
  addToDataLayer(obj: any): void {
    // In Some cases, we just want to add to the data layer, but not cause an event to fire
    this.gtmService.addToDataLayer(obj);
  }

  /**
   * Tracking
   */
  track(key: AnalyticsBasicEvent | string, data?: any, trackOnce?: boolean): any {
    if (this.tracked[key]) {
      return;
    }

    const details = Object.assign({}, this.keys[key] || {}); // Clone

    // For each of the details, we want to replace anything dynamic
    Object.keys(details).forEach((k) => {
      const matches = /\${(.*?)}/.exec(details[k]);
      if (matches && matches[1]) {
        const prop = this.getProperty({ data }, matches[1]) || matches[1];
        details[k] = details[k].replace(/\${(.*?)}/, prop);
      }
    });

    // This ensures that two events can't fire in very rapid succession
    // And also in the rare case that a 'interecepted' <a> tag also has a bwTrackAs directive
    if (!trackOnce) {
      setTimeout(() => {
        this.tracked[key] = undefined;
      }, 500);
    }

    this.log(details);

    this.tracked[key] = details;

    if ((details.platforms || []).indexOf('tvsquared') > -1) {
      this.untilIdleService.queue(() => {
        this.tvsquaredService.trackEvent(details.category, details.event, details.label);
      }, 1);
    }

    if (this.configService.getConfig().hotjarEnabled && (details.platforms || []).indexOf('hotjar') > -1) {
      this.untilIdleService.queue(() => {
        this.hotjarService.trackEvent(details.label);
      }, 1);
    }

    if ((details.platforms || []).indexOf('gtm') > -1) {
      this.untilIdleService.queue(() => {
        this.gtmService.addToDataLayer({
          event: details.event,
          eventName: details.event,
          category: details.category,
          label: details.label,
          value: details.value
        });
      }, 1);
    }

    if ((details.platforms || []).indexOf('gtm') > -1) {
      this.untilIdleService.queue(() => {
        this.gaService.trackEvent({
          eventAction: details.event,
          eventCategory: details.category,
          eventLabel: details.label,
          eventValue: details.value
        });
      }, 1);
    }

    if ((details.platforms || []).indexOf('ga4') > -1) {
      this.untilIdleService.queue(() => {
        this.gtagServiceGA4.trackClick({
          eventAction: details.event,
          eventCategory: details.category,
          eventLabel: details.label,
          eventValue: details.value
        });
      }, 1);
    }

    if (this.configService.getConfig().heapEnabled) {
      if ((details.platforms || []).indexOf('heap') > -1) {
        this.untilIdleService.queue(() => {
          this.heapService.logEvent(details.event, {
            category: details.category,
            label: details.label,
            value: details.value
          });
        }, 1);
      }
    }

    return details;
  }

  /**
   * Track a url
   * @param url
   */
  trackPage(url: string): void {
    this.untilIdleService.queue(() => {
      if (!this.isFirstPageView) {
        this.gtagServiceGA4.sendPageView();
      }
    }, 0);

    this.isFirstPageView = false;
    // Additional tracking can wait
    this.untilIdleService.queue(() => {
      this.gtmService.addToDataLayer({
        event: 'web:state:change',
        state: {
          url
        }
      });
    }, 0);
    this.untilIdleService.queue(() => {
      this.pinterestService.trackPage();
    }, 1);
    if (this.configService.getConfig().quoraEnabled) {
      this.untilIdleService.queue(() => {
        this.quoraService.trackPageView();
      });
    }
    if (this.configService.getConfig().snapchatEnabled) {
      this.untilIdleService.queue(() => {
        this.snapchatService.trackPage();
      }, 0);
    }
    this.untilIdleService.queue(() => {
      this.facebookMarketingService.trackPage();
    }, 0);
    this.untilIdleService.queue(() => {
      this.tiktokMarketingService.trackPage();
    }, 0);
    if (this.configService.getConfig().heapEnabled) {
      this.untilIdleService.queue(() => {
        this.heapService.logEvent('web:state:change', {
          state: {
            url
          }
        });
      }, 0);
    }
    if (this.configService.getConfig().tvSquaredEnabled) {
      this.untilIdleService.queue(() => {
        this.tvsquaredService.trackPage();
      }, 0);
    }
    if (this.configService.getConfig().drTvEnabled) {
      this.untilIdleService.queue(() => {
        this.drtvService.trackPage();
      }, 0.5);
    }
    if (this.configService.getConfig().bingUetEnabled) {
      this.untilIdleService.queue(() => {
        this.bingService.trackPage();
      }, 0);
    }
    if (this.configService.getConfig().hotjarEnabled) {
      this.untilIdleService.queue(() => {
        this.hotjarService.trackPageView(url);
      }, 0);
    }
    if (this.configService.getConfig().braze?.enabled) {
      this.untilIdleService.queue(() => {
        this.appboyService.trackPageView(url);
      }, 0);
    }
  }

  /**
   * Triggers optimize experiments
   * @param url
   */
  optimizeActivate(url): void {
    const urlWithForwardSlash = url.indexOf('/') !== 0 ? `/${url}` : url;
    const urlWithNoParams = urlWithForwardSlash.split('?')[0];
    this.gtmService.addToDataLayer({
      stateChangeToUrl: urlWithNoParams,
      event: 'optimize.activate'
    });
  }

  /* ================================
  Ecommerce Tracking
  ================================ */

  trackAddonImpression(addon: Addon, addonPosition: number, product: Product, placement: string): void {
    this.untilIdleService.queue(() => {
      this.heapService.logAdvancedEvent('addonImpression', {
        addon,
        addonPosition,
        placement,
        product: product.clone()
      });
    }, 1);
  }

  trackImpressions(
    products: Product[],
    start: number,
    howMany: number,
    listType: string,
    listValue?: string,
    serverTime?: dayjs.Dayjs,
    productIndex?: number,
    producutIdentifierId?: string
  ): void {
    const listName = `${listType}${listValue ? `/${listValue}` : ''}`;

    // Filter out any subs promo cards in the carousel
    const originalProds = products.slice().filter((item) => item);

    const trackProducts = products.slice(start, start + howMany).map((p, index) => ({
      id: p.id,
      name: p.name,
      category: p.collectionName,
      list: listName,
      position: productIndex !== undefined ? productIndex : start + index + 1
    }));

    this.untilIdleService.queue(() => {
      this.gtmService.addToDataLayer({
        event: 'web:ecomm:sku:impression',
        ecommerce: {
          actionField: {
            list: listName
          },
          impressions: trackProducts
        }
      });
      this.gtmService.clearDataLayerKey('ecommerce');
    }, 1);

    this.untilIdleService.queue(() => {
      this.facebookMarketingService.viewProducts(products, listName);
    }, 1);

    if (this.configService.getConfig().snapchatEnabled) {
      this.untilIdleService.queue(() => {
        this.snapchatService.viewProducts(listName);
      }, 1);
    }

    if (this.configService.getConfig().heapEnabled) {
      this.untilIdleService.queue(() => {
        this.heapService.logEvent('web:ecomm:sku:impression', {
          actionField: {
            list: listName
          },
          impressions: trackProducts
        });
      }, 1);
    }

    products.slice(start, start + howMany).forEach((product, index) => {
      const increment = start + index + 1;
      const updatedPostion = originalProds.findIndex((p) => p.id === product.id) + 1;

      this.untilIdleService.queue(() => {
        const daysUntilDeliveryFrom =
          product.type !== 'fake_product' ? product.deliverableFrom.startOf('day').diff(serverTime.startOf('day'), 'day') : undefined;

        // The ModularContent Card card is created with ID -1 and inserted into the product list array
        if (product.type === 'fake_product') {
          if (this.configService.getConfig().heapEnabled) {
            this.heapService.logAdvancedEvent('ModularContentBlockImpression', {
              modularProductCardIndex: updatedPostion,
              modularProductCardName: product.tags[0]
            });
          }
          return;
        }

        if (this.configService.getConfig().heapEnabled) {
          this.heapService.logAdvancedEvent('productImpression', {
            carouselLength: products.length,
            daysUntilDeliverable: daysUntilDeliveryFrom < 1 ? 0 : daysUntilDeliveryFrom - 1,
            productUndeliverableTomorrow: product.isPreorder,
            product: product.clone(),
            productPosition: updatedPostion,
            listType: {
              type: listType,
              value: listValue
            },
            productListUniqueIndentifier: producutIdentifierId
          });
        }
      }, 1);
    });
  }

  /**
   * Track Country Channge
   * @param oldCountry
   * @param newCountry
   */
  trackCountryChange(oldCountry: Country, newCountry: Country): void {
    if (oldCountry?.codes.length && newCountry?.codes?.length)
      this.track('general.shipping.country', {
        countryId: newCountry.id
      });

    this.addToDataLayer({
      deliveryCountryId: newCountry.id
    });

    this.setDimension('deliveryCountry', `Manual|${oldCountry.codes[0].toUpperCase()}>${newCountry.codes[0].toUpperCase()}`);
  }

  /**
   * GA Impressions work slighlty differently
   * @param products
   * @param listType
   * @param listValue
   * @param brandId
   */
  trackGaProductImpressions(products: any[], listType: string, listValue: string, brandId: string): void {
    const listName = `${listType}${listValue ? `/${listValue}` : ''}`;
    let currency = products[0]?.product?.price?.currency;

    const prods: GaProductImpressions[] = products.map((p) => {
      return {
        id: p.product.id,
        name: p.product.name,
        category: p.product.collectionName,
        list: listName,
        price: p.product?.getPrice()?.price / 100,
        position: p.index + 1,
        brand: brandId
      };
    });

    this.untilIdleService.queue(() => {
      this.gaService.trackImpression(prods, currency);
      this.gaService.trackModularProductView(products);
    }, 1);
  }
  /**
   * Impression fired for products in subs modals
   */
  trackSubscriptionModalProductImpression(prod: Product): void {
    this.untilIdleService.queue(() => {
      this.heapService.logAdvancedEvent('monthlyFlowersModalProductImpression', {
        product: prod,
        modalLocation: this.stateService.getCurrent().url
      });
    }, 1);
  }

  /**
   * Track user login
   * @param method
   */
  trackUserLogin(method: string): void {
    this.untilIdleService.queue(() => {
      this.gtagServiceGA4.trackUserLogin(method);
    }, 1);
  }

  /**
   * Track user register
   * @param method
   */
  trackUserRegister(method: string): void {
    this.untilIdleService.queue(() => {
      this.gtagServiceGA4.trackUserRegister(method);
    }, 1);
  }

  /**
   * Track the view cart/basket
   * @param orders
   */
  trackViewBasket(purchase: Purchase): void {
    this.untilIdleService.queue(() => {
      this.gtagServiceGA4.trackViewCart(purchase);
      if (this.configService.getConfig().adwordsMidFunnel.enabled) {
        this.gtagService.trackViewCart();
      }
    }, 1);
  }

  /**
   * Track the remove item from cart/basket
   * @param order
   */
  removeFromBasket(order: Order): void {
    const listType = this.locationService.getListType();

    this.untilIdleService.queue(() => {
      this.gtagServiceGA4.removeFromCart(order, listType.value, this.getProductListUniqueIndentifier());
    }, 0);
  }

  /**
   * Track add payment info
   * @param method
   * @param purchase
   */
  trackAddPaymentInfo(method: string, purchase: Purchase): void {
    this.untilIdleService.queue(() => {
      this.gtagServiceGA4.trackAddPaymentInfo(method, purchase);
    }, 1);
  }

  /**
   * Track the add to wishlist/favourite
   * @param orders
   */
  trackAddToFavourites(product: Product): void {
    const listType = this.locationService.getListType();

    this.untilIdleService.queue(() => {
      this.gtagServiceGA4.trackAddToWishlist(product, listType.value, this.getProductListUniqueIndentifier());
    }, 1);
  }

  /**
   * Track add shipping info
   * @param orders
   */
  trackAddDeliveryInfo(order: Order): void {
    const listType = this.locationService.getListType();

    this.untilIdleService.queue(() => {
      this.gtagServiceGA4.trackAddShippingInfo(order, listType.value, this.getProductListUniqueIndentifier());
    }, 1);
  }

  /**
   * Track the add promocode
   * @param orders
   */
  trackAddPromocode(userAction: string, code: string, isCodeValid: boolean): void {
    this.untilIdleService.queue(() => {
      this.gtagServiceGA4.trackAddPromoCode(userAction, code, isCodeValid);
    }, 1);
  }

  /**
   * Track view list items
   * @param products
   * @param productIds
   */
  trackViewListItems(products: Product[], productIds: Number[]): void {
    const listType = this.locationService.getListType();
    this.untilIdleService.queue(() => {
      this.gtagServiceGA4.trackViewListItems(products, productIds, listType.value);

      if (this.configService.getConfig().adwordsMidFunnel.enabled) {
        this.gtagService.trackViewListItems();
      }
    }, 1);
  }

  // TODO NOT EDIT ORDER
  /**
   * Track adding to purchase
   * @param order
   */
  trackAddToPurchase(order: Order): void {
    const listType = this.locationService.getListType();
    const products = [];
    products.push({
      name: order.product.name,
      id: order.product.id,
      price: (order.getPrice(true, false).price / 100).toFixed(2),
      currency: order.getPrice().currency,
      variant: order.getTrackedDurationName(),
      quantity: order.getTotalDeliveries()
    });

    (order.addons || []).forEach((addon) => {
      products.push({
        name: addon.name,
        id: addon.id,
        price: (addon.getPrice().price / 100).toFixed(2),
        currency: addon.getPrice().currency,
        quantity: 1 // Always one
      });
    });

    if (this.configService.getConfig().quoraEnabled) {
      this.untilIdleService.queue(() => {
        this.quoraService.trackAddToPurchase();
      });
    }

    this.untilIdleService.queue(() => {
      this.facebookMarketingService.addToPurchase(order);
    }, 1);

    if (this.configService.getConfig().tiktokPixelEnabled) {
      this.untilIdleService.queue(() => {
        this.tiktokMarketingService.addToCart(order);
      }, 1);
    }

    this.untilIdleService.queue(() => {
      this.pinterestService.addToPurchase(order);
    }, 1);

    if (this.configService.getConfig().snapchatEnabled) {
      this.untilIdleService.queue(() => {
        this.snapchatService.addToPurchase(order);
      }, 1);
    }

    if (this.configService.getConfig().heapEnabled) {
      this.untilIdleService.queue(() => {
        this.heapService.logEvent('web:ecomm:purchase:add', {
          currencyCode: order.getPrice().currency,
          add: {
            products
          }
        });
      }, 1);
    }

    this.untilIdleService.queue(() => {
      this.gtmService.addToDataLayer({
        event: 'web:ecomm:purchase:add',
        // 'add' is an 'enhanced ecommerce' actionFieldObject measures.
        ecommerce: {
          currencyCode: order.getPrice().currency,
          add: {
            products
          }
        }
      });
      this.gtmService.clearDataLayerKey('ecommerce');
    }, 1);

    this.untilIdleService.queue(() => {
      this.gaService.trackAddToPurchase(order);
      this.gtagServiceGA4.trackAddToPurchase(order, listType.value, this.getProductListUniqueIndentifier());
    }, 1);

    this.untilIdleService.queue(() => {
      this.appboyService.logEvent('web:ecomm:purchase:add', {
        name: order.product.name,
        id: order.product.id
      });
    }, 1);
  }

  /**
   * Track Purchase on an order level
   * @param orders
   * @param user
   */
  trackPurchasedOrders(purchase: Purchase, user: User): void {
    let skuList: number[] = [];
    purchase.orders.forEach((order) => skuList.push(order.id));

    purchase.orders.forEach((order) => {
      const products = [];
      products.push({
        name: order.product.name,
        id: order.product.id,
        price: (order.price.price / 100).toFixed(2),
        variant: order.getTrackedDurationName(),
        quantity: 1
      });

      if (this.configService.getConfig().heapEnabled) {
        this.untilIdleService.queue(() => {
          const ord = order.clone();
          this.heapService.logAdvancedEvent('orderConfirmation', {
            purchase,
            order: ord,
            skuList: skuList.toString(),
            skuTotal: skuList.length
          });
        }, 1);
      }

      this.untilIdleService.queue(() => {
        this.gtagServiceGA4.trackPurchase(purchase);
      }, 1);

      // TODO: We can't get addon as the pricing is not seperate
      // if (order.addon) {
      //   products.push({
      //     name: order.addon.name,
      //     id: order.addon.id,
      //     price: order.addon.getPrice().price,
      //     quantity: 1
      //   });
      // }

      this.untilIdleService.queue(() => {
        this.gtmService.addToDataLayer({
          event: 'web:ecomm:order',
          email: user.email.address,
          ecommerce: {
            currencyCode: order.price.currency,
            purchase: {
              products,
              actionField: {
                id: order.id,
                revenue: (order.price.price / 100).toFixed(2)
              }
            }
          }
        });
        this.gtmService.clearDataLayerKey('ecommerce');
      }, 1);
    });
  }

  /**
   * Track that the user has entered a cad, or selected a payment type
   */
  trackCardSelected(): void {
    this.untilIdleService.queue(() => {
      this.facebookMarketingService.startedPayment();
    }, 1);

    if (this.configService.getConfig().snapchatEnabled) {
      this.untilIdleService.queue(() => {
        this.snapchatService.startedPayment();
      }, 1);
    }

    if (this.configService.getConfig().quoraEnabled) {
      this.untilIdleService.queue(() => {
        this.quoraService.trackAddedPayment();
      });
    }
  }

  /**
   * Track Purchase
   * @param purchase
   * @param user
   */
  trackPurchase(purchase: Purchase, user: User): void {
    const products = purchase.orders.map((ord) => {
      return {
        name: ord.product.name,
        id: ord.product.id,
        price: (ord.price.price / 100).toFixed(2),
        currency: ord.price.currency,
        variant: ord.getTrackedDurationName(),
        quantity: 1
      };
    });

    this.untilIdleService.queue(() => {
      this.appsflyerService.trackPurchase(purchase);
    });

    if (this.configService.getConfig().trustedShopEnabled) {
      this.untilIdleService.queue(() => {
        this.trustedShopsService.trackPurchase(purchase, user);
      }, 1);
    }

    if (this.configService.getConfig().tvSquaredEnabled) {
      this.untilIdleService.queue(() => {
        this.tvsquaredService.trackPurchase(purchase);
      }, 0);
    }

    if (this.configService.getConfig().drTvEnabled) {
      this.untilIdleService.queue(() => {
        this.drtvService.trackPurchase(purchase, user);
      }, 0.5);
    }

    this.untilIdleService.queue(() => {
      this.pinterestService.trackPurchase(purchase);
    }, 1);

    if (this.configService.getConfig().quoraEnabled) {
      this.untilIdleService.queue(() => {
        this.quoraService.trackPurchase();
      });
    }

    if (this.configService.getConfig().rakutenEnabled) {
      this.untilIdleService.queue(() => {
        this.rakutenService.trackPurchase(purchase, user);
      }, 1);
    }

    this.untilIdleService.queue(() => {
      this.branchService.trackPurchase(purchase);
    }, 1);

    if (this.configService.getConfig().zyperTrackingEnabled) {
      this.untilIdleService.queue(() => {
        this.zyperService.trackPurchase(purchase).catch((e) => {}); // Don't care if invalid
      }, 1);
    }

    this.untilIdleService.queue(() => {
      this.facebookMarketingService.confirmPurchase(purchase);
    }, 1);

    if (this.configService.getConfig().tiktokPixelEnabled) {
      this.untilIdleService.queue(() => {
        this.tiktokMarketingService.completePayment(purchase);
      }, 1);
    }

    if (this.configService.getConfig().partnerizeEnabled) {
      this.untilIdleService.queue(() => {
        this.partnerizeService.trackPurchase(purchase, user);
      }, 1);
    }

    this.untilIdleService.queue(() => {
      this.inflcrService.trackPurchase();
    }, 1);

    if (this.configService.getConfig().snapchatEnabled) {
      this.untilIdleService.queue(() => {
        this.snapchatService.confirmPurchase(purchase);
      }, 1);
    }

    this.untilIdleService.queue(() => {
      this.gtmService.addToDataLayer({
        event: 'web:ecomm:confirmation',
        email: user.email.address,
        ecommerce: {
          currencyCode: purchase.price.currency,
          purchase: {
            products,
            actionField: {
              id: purchase.id,
              coupon: purchase.discount ? purchase.discount.code : '',
              revenue: (purchase.price.price / 100).toFixed(2)
            }
          }
        }
      });
      this.gtmService.clearDataLayerKey('ecommerce');
    }, 1);

    this.untilIdleService.queue(() => {
      this.gaService.trackPurchaseComplete(purchase, products);
    }, 1);

    if (this.configService.getConfig().adwordsEnabled) {
      this.untilIdleService.queue(() => {
        this.gtagService.trackConversion(purchase, user);
      }, 1);
    }

    if (this.configService.getConfig().heapEnabled) {
      this.untilIdleService.queue(() => {
        this.heapService.logEvent('web:ecomm:confirmation', {
          currencyCode: purchase.price.currency,
          purchase: {
            products,
            actionField: {
              id: purchase.id,
              revenue: (purchase.price.price / 100).toFixed(2)
            }
          }
        });
      }, 1);

      this.untilIdleService.queue(() => {
        this.heapService.logAdvancedEvent('purchaseConfirmation', {
          purchase
        });
      }, 1);
    }
  }

  /**
   * Track viewing of a product
   * @param product
   * @param listType
   * @param listValue
   */

  trackProductView(
    product: Product,
    listType: string,
    listValue?: string,
    logAppboy?: boolean,
    carouselLength?: number,
    productIndex?: number
  ): void {
    const listName = `${listType}${listValue ? `/${listValue}` : ''}`;
    this.untilIdleService.queue(() => {
      this.gtmService.addToDataLayer({
        event: 'web:ecomm:sku:view',
        ecommerce: {
          detail: {
            actionField: {
              list: listName
            },
            products: [
              {
                name: product.name,
                id: product.id,
                category: product.collectionName
              }
            ]
          }
        }
      });
      this.gtmService.clearDataLayerKey('ecommerce');
    }, 1);

    this.untilIdleService.queue(() => {
      this.gaService.trackProductView(product);
      this.gtagServiceGA4.trackProductView(product, listValue, this.getProductListUniqueIndentifier());

      if (this.configService.getConfig().adwordsMidFunnel.enabled) {
        this.gtagService.trackProductView();
      }
    }, 1);

    this.untilIdleService.queue(() => {
      this.facebookMarketingService.viewProductModal(product);
    }, 1);

    if (this.configService.getConfig().heapEnabled) {
      this.untilIdleService.queue(() => {
        this.heapService.logEvent('web:ecomm:sku:view', {
          detail: {
            actionField: {
              list: listName
            },
            products: [
              {
                name: product.name,
                id: product.id
              }
            ]
          }
        });
      }, 1);

      this.untilIdleService.queue(() => {
        this.heapService.logAdvancedEvent('productView', {
          product,
          carouselLength,
          listType: {
            value: listValue,
            type: listType
          },
          productPosition: productIndex + 1,
          productFindingMethod: undefined
        });
      }, 1);
    }

    if (logAppboy) {
      this.untilIdleService.queue(() => {
        this.appboyService.trackCheckoutProgress('web:ecomm:sku:view', 'last_sku_viewed', product);
      }, 1);
    }
  }

  /**
   * Get the Product List Unique Indentifier
   * TODO: probably better to create a common service to get this for heap and ga
   * @param products
   */
  getProductListUniqueIndentifier(): string {
    const productListUniqueIndentifier = this.heapService.getGlobalHeapProperties().productListUniqueIndentifier;
    if (productListUniqueIndentifier) {
      return productListUniqueIndentifier;
    }
    return '';
  }

  /**
   * Track addons selected
   * @param previous
   * @param current
   * @param purchase
   * @param order
   * @param listType
   */
  trackAddonsSelected(
    previous: Addon[],
    current: Addon[],
    purchase?: Purchase,
    order?: Order,
    listType?: any,
    product?: Product,
    placement?: 'addon-pdp' | 'addon-pdm' | 'addon-modal' | 'addon-picker'
  ): void {
    const added = (current || []).filter((a) => a.id && !(previous || []).find((oa) => oa.id === a.id));
    const removed = (previous || []).filter((oa) => oa.id && !current.find((a) => oa.id === a.id));

    added.forEach((addon) => {
      this.trackInHeap('addOnAdded', {
        listType,
        order,
        product,
        addon,
        purchase,
        placement
      });
    });

    removed.forEach((addon) => {
      this.trackInHeap('addOnRemoved', {
        listType,
        order,
        product,
        addon,
        purchase,
        placement
      });
    });
  }

  /**
   * Track multiple product detail viewed
   * @param products
   */
  trackMultipleProductDetailViewed(products: Product[]): void {
    this.untilIdleService.queue(() => {
      this.gaService.trackProductDetailViewed(products);
    }, 1);
  }

  // TODO: NOT EDIT ORDER
  /**
   * Track product selected
   * @param product
   * @param duration
   * @param positionInList
   * @param listType
   * @param listValue
   */
  trackProductSelected(
    product: Product,
    duration: number,
    positionInList: number,
    listType: string,
    listValue?: string,
    logAppboy?: boolean,
    purchase?: Purchase,
    order?: Order,
    carouselLength?: number,
    productSelectedTriggerModal?: boolean
  ): void {
    const listName = `${listType}${listValue ? `/${listValue}` : ''}`;
    const orderType = product.getTrackedDurationName(duration);

    // If a product was selected that does not start checkout but opens a modal
    // i.e Vase, Pots, Subscription then we fire productSelected event and return
    if (productSelectedTriggerModal) {
      if (this.configService.getConfig().heapEnabled) {
        this.untilIdleService.queue(() => {
          this.heapService.logAdvancedEvent('productSelected', {
            product
          });
        }, 1);

        return;
      }
    }

    this.untilIdleService.queue(() => {
      this.gtmService.addToDataLayer({
        event: 'web:ecomm:sku:select',
        ecommerce: {
          click: {
            actionField: {
              list: listName
            },
            products: [
              {
                name: product.name,
                id: product.id,
                category: product.collectionName,
                variant: orderType,
                position: positionInList + 1
              }
            ]
          }
        }
      });
      this.gtmService.clearDataLayerKey('ecommerce');
    }, 1);

    this.untilIdleService.queue(() => {
      this.gaService.trackProductSelected(product, orderType);
      this.gtagServiceGA4.trackProductSelected(product, purchase, duration, listValue, this.getProductListUniqueIndentifier());

      if (this.configService.getConfig().adwordsMidFunnel.enabled) {
        this.gtagService.trackProductSelected();
      }
    }, 1);

    this.untilIdleService.queue(() => {
      this.facebookMarketingService.selectProduct(product);
    }, 1);

    if (this.configService.getConfig().quoraEnabled) {
      this.untilIdleService.queue(() => {
        this.quoraService.trackCheckoutStarted();
      });
    }

    if (this.configService.getConfig().snapchatEnabled) {
      this.untilIdleService.queue(() => {
        this.snapchatService.selectProduct(product);
      }, 1);
    }

    if (this.configService.getConfig().heapEnabled) {
      this.untilIdleService.queue(() => {
        this.heapService.logEvent('web:ecomm:sku:select', {
          click: {
            actionField: {
              list: listName
            },
            products: [
              {
                name: product.name,
                id: product.id,
                category: product.collectionName,
                variant: product.getTrackedDurationName(duration),
                position: positionInList + 1
              }
            ]
          }
        });
      }, 1);

      this.untilIdleService.queue(() => {
        this.heapService.logAdvancedEvent('startCheckout', {
          order,
          purchase,
          product,
          carouselLength,
          productPosition: positionInList + 1,
          listType: {
            type: listType,
            value: listValue
          }
        });
      }, 1);
    }

    if (this.configService.getConfig().drTvEnabled) {
      this.untilIdleService.queue(() => {
        this.drtvService.trackDrtvGoal('checkoutStart', {
          skuId: product.id,
          orderType: order.type,
          productPrice: product.getPrice().price / 100,
          productName: product.name
        });
      }, 0.5);
    }

    if (logAppboy) {
      this.untilIdleService.queue(() => {
        this.appboyService.trackCheckoutProgress('web:ecomm:sku:select', 'last_sku_checkout_started', product);
      }, 1);
    }
  }

  /**
   * Track recipient being added within checkout
   * @param product
   * @param recipient
   */
  trackRecipientAdded(product: Product, recipient: string): void {
    this.untilIdleService.queue(() => {
      this.appboyService.trackCheckoutProgress('web:ecomm:recipient:name', 'last_recipient_checkout_started', product, recipient);
    });
  }

  heapTrackFilters(eventName: string, obj: any): void {
    if (this.configService.getConfig().heapEnabled) {
      this.untilIdleService.queue(() => {
        this.heapService.logAdvancedEvent(eventName, obj);
      });
    }
  }

  /**
   * Track modalView
   * @param modalType
   */
  trackModalView(modalType: string): void {
    this.gtagServiceGA4.trackModalView(modalType);
  }

  /**
   * Track the field enter
   * @param formName
   * @param fieldName
   */
  trackEnterField(formName: FormName, fieldName: FieldName): void {
    this.gtagServiceGA4.trackEnterField(formName, fieldName);
  }

  /**
   * Track subscription soft lead
   * @param type
   */
  trackSubscriptionSoftLead(type: string): void {
    this.gtagServiceGA4.trackSubscriptionSoftLead(type);
  }

  /**
   * Track subscription purchase
   * @param purchase
   */
  subscriptionPurchase(purchase: Purchase): void {
    this.gtagServiceGA4.trackSubscriptionPurchase(purchase);
  }

  /**
   * Track the form submit
   * @param formName
   */
  trackFormSubmit(formName: FormName): void {
    this.gtagServiceGA4.trackFormSubmit(formName);
  }

  /**
   * Track sort interactions
   * @param optionSelected
   */
  trackSortingOption(optionSelected: string): void {
    this.gtagServiceGA4.trackSortingOption(optionSelected);
  }

  /**
   * Track filter interactions
   * @param ga4Info
   */
  trackFilterOptions(ga4Info): void {
    this.gtagServiceGA4.trackFilterOptions(ga4Info);
  }

  /**
   * Call the drtv service to track the goal defined by the eventName and setting the custom variables passed through drtvData
   * @param eventName
   * @param drtvData
   */
  trackDrtvGoals(eventName: string, drtvData: DrtvData): void {
    this.drtvService.trackDrtvGoal(eventName, drtvData);
  }

  /**
   * Track nav interactions
   * @param navAnalyticsInfo
   */
  trackInteractionNav(navAnalyticsInfo: NavAnalyticsInfo): void {
    this.gtagServiceGA4.trackInteractionNav(navAnalyticsInfo);
  }

  /**
   * Track view experiment by firing a GA4 event
   * @param experiment - object that contains experiment details
   */
  trackViewExperiment(experiment: ExperimentOptions): void {
    this.gtagServiceGA4.trackViewExperiment(experiment);
  }

  /**
   * @description track when user successfully joins rewards
   * @param {string} origin
   */
  trackJoiningRewards(origin: string): void {
    const user = this.userService.getUser();

    this.trackInHeap('successfullyJoinedRewards', {
      loggedInUser: user.isLoggedIn() ?? false,
      registeredUser: user.email?.hasRegistered ?? false,
      rewardsMember: !!user.loyaltySchemeMembershipId ?? false,
      rewardsJoinOrigin: origin
    });
  }
}
