import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CurrencyPipe, AsyncPipe, CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxPopperjsModule } from 'ngx-popperjs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { HasConfig } from 'Shared/guards/hasConfig.guard';
import { PopulatedPurchase } from 'Shared/guards/populatedPurchase.guard';

import { BackendService } from 'Shared/services/backend.service';
import { WindowRefService } from 'Shared/services/window.service';
import { DateUtilsService } from 'Shared/utils/date-utils.service';
import { LocationService } from 'Shared/services/location.service';
import { InvoiceService } from 'Shared/services/invoice.service';
import { TranslationsServiceService } from 'Shared/utils/translationsService.service';
import { TicketService } from 'Shared/services/ticket.service';
import { FileService } from 'Shared/services/file.service';
import { ServiceBarService } from 'Shared/services/service-bar.service';
import { CustomKeysService } from 'Shared/services/custom-keys.service';
import { UntilIdleService } from 'Shared/services/until-idle.service';
import { IntersectionService } from 'Shared/services/intersection.service';

import { ContentComponent } from 'Shared/components/content/content.component';

import { AddressPipe } from 'Shared/pipes/address.pipe';
import { Highlight } from 'Shared/pipes/highlight.pipe';
import { PricePipe } from 'Shared/pipes/price.pipe';
import { DayOfWeekPipe } from 'Shared/pipes/day-of-week.pipe';
import { FrequencyPipe } from 'Shared/pipes/frequency.pipe';
import { DurationPipe } from 'Shared/pipes/duration.pipe';
import { ToFixedPipe } from 'Shared/pipes/to-fixed.pipe';
import { FromNowPipe } from 'Shared/pipes/from-now.pipe';
import { UserCreditPipe } from 'Shared/pipes/user-credit.pipe';
import { ProductNamePipe } from 'Shared/pipes/product-name.pipe';
import { DiscountMessagePipe } from 'Shared/pipes/discount-message.pipe';
import { SubscriptionSavingPipe } from 'Shared/pipes/subscription-saving.pipe';
import { TextReplacePipe } from 'Shared/pipes/text-replace.pipe';
import { EllipsisPipe } from 'Shared/pipes/ellipsis.pipe';
import { RelativeDatePipe } from 'Shared/pipes/relative-date.pipe';
import { SiteConfigPipe } from 'Shared/pipes/site-config.pipe';

import { StickyClassesDirective } from 'Shared/directives/sticky-classes.directive';
import { BwInteractionStateClassesDirective } from 'Shared/directives/interaction-state-classes.directive';
import { ValidationMessagesComponent } from 'Shared/components/validation-messages/validation-messages.component';
import { LoadingSpinnerComponent } from 'Shared/components/loading-spinner/loading-spinner.component';
import { DatePickerComponent } from 'Shared/components/date-picker/date-picker.component';
import { AddressSearchComponent } from 'Shared/components/address-search/address-search.component';
import { AddressFormComponent } from 'Shared/components/address-form/address-form.component';
import { AddressFormNewComponent } from 'Shared/components/address-form-new/address-form-new.component';
import { CardEntryComponent } from 'Shared/components/card-entry/card-entry.component';
import { TrackAsDirective } from 'Shared/directives/track-as.directive';
import { StaticClickDirective } from 'Shared/directives/static-click.directive';
import { ElasticDirective } from 'Shared/directives/elastic.directive';
import { HrefInterceptorComponent } from 'Shared/components/href-interceptor/href-interceptor.component';
import { PurchaseBasketComponent } from 'Shared/components/purchase-basket/purchase-basket.component';
import { PurchaseBasketDiscountMessageComponent } from 'Shared/components/purchase-basket-discount-message/purchase-basket-discount-message.component';
import { ReviewStarsComponent } from 'Shared/components/review-stars/review-stars.component';
import { ReviewStarsTrustpilotComponent } from 'Shared/components/review-stars-trustpilot/review-stars-trustpilot.component';
import { ExperimentComponent } from 'Shared/components/experiment/experiment.component';
import { ExperimentEventDirective } from 'Shared/directives/experiment-event.directive';
import { AddressSavedComponent } from 'Shared/components/address-saved/address-saved.component';
import { PageCategoryNavItemDirective } from 'Shared/directives/page-category-nav-item.directive';
import { I18nComponent } from 'Shared/components/i18n/i18n.component';
import { ServiceBarComponent } from 'Shared/components/service-bar/service-bar.component';
import { HrefActiveDirective } from 'Shared/directives/href-active.directive';
import { SeoComponent } from 'Shared/components/seo/seo.component';

import { ContentDisplayComponent } from 'Shared/components/content-display/content-display.component';
import { I18nSelectorComponent } from 'Shared/components/i18n-selector/i18n-selector.component';
import { ContentSpotComponent } from 'Shared/components/content-spot/content-spot.component';
import { FormValidationIconComponent } from 'Shared/components/form-validation-icon/form-validation-icon.component';
import { FormValidationMessagesComponent } from 'Shared/components/form-validation-messages/form-validation-messages.component';
import { FormValidationMessageComponent } from 'Shared/components/form-validation-message/form-validation-message.component';
import { OpenContentModalComponent } from 'Content/components/open-content-modal/open-content-modal.component';
import { ComparePricePipe } from 'Shared/pipes/compare-price.pipe';
import { StagingHelperComponent } from 'Content/components/staging-helper/staging-helper.component';
import { LaunchModalComponent } from 'Shared/components/launch-modal/launch-modal.component';

import { GiftCardComponent } from 'Shared/components/gift-card/gift-card.component';
import { MentionMeOrderTrackingComponent } from 'Shared/components/third-parties/mention-me-order-tracking/mention-me-order-tracking.component';
import { MentionMeIframeComponent } from 'Project/shared/components/third-parties/mention-me-iframe/mention-me-iframe.component';
import { ReviewSummaryComponent } from 'Shared/components/review-summary/review-summary.component';
import { ReviewsService } from 'Shared/services/reviews.service';
import { ReviewsModelService } from 'Shared/models/reviews-model.service';
import { ContentfulImageComponent } from 'Content/components/contentful-image/contentful-image.component';
import { ContentfulPipeComponent } from 'Content/components/contentful-pipe/contentful-pipe.component';
import { ContentfulEventComponent } from 'Content/components/contentful-event/contentful-event.component';
import { VideoComponent } from 'Shared/components/video/video.component';

import { RedirectComponent } from 'Shared/components/redirect/redirect.component';
import { SeparatorBarComponent } from 'Shared/components/separator-bar/separator-bar.component';
import { LocaleDatePipe } from 'Shared/pipes/locale-date.pipe';
import { LocaleRelativeDatePipe } from 'Shared/pipes/locale-relative-date.pipe';
import { KeepShoppingComponent } from 'Shared/components/keep-shopping/keep-shopping.component';
import { LiveContactComponent } from 'Shared/components/live-contact/live-contact.component';
import { DotPaginationComponent } from 'Shared/components/dot-pagination/dot-pagination.component';
import { ImageSizePipe } from 'Shared/pipes/image-size.pipe';
import { OccasionRemindersModelService } from 'Shared/models/occasion-reminders-model.service';
import { OccasionOfferComponent } from 'Shared/components/occasion-offer/occasion-offer.component';
import { OccasionBackgroundsComponent } from 'Shared/components/occasion-backgrounds/occasion-backgrounds.component';
import { PossessivePipe } from 'Shared/pipes/possessive.pipe';
import { OccasionLaunchComponent } from 'Shared/components/occasion-launch/occasion-launch.component';
import { OccasionOfferMessageComponent } from 'Shared/components/occasion-offer-message/occasion-offer-message.component';
import { EmailPromptComponent } from 'Shared/components/email-prompt/email-prompt.component';
import { NameInputComponent } from 'Shared/components/name-input/name-input.component';
import { CountdownComponent } from 'Shared/components/countdown/countdown.component';
import { ReviewsStatsComponent } from 'Shared/components/reviews-stats/reviews-stats.component';
import { ReviewsTestimonialsCarouselComponent } from 'Shared/components/reviews-testimonials-carousel/reviews-testimonials-carousel.component';
import { ReviewTestimonialCardComponent } from 'Shared/components/review-testimonial-card/review-testimonial-card.component';
import { ReviewsSlimBannerComponent } from 'Shared/components/reviews-slim-banner/reviews-slim-banner.component';
import { ReadMoreTriggerComponent } from 'Shared/components/read-more-trigger/read-more-trigger.component';
import { ReadLessTriggerComponent } from 'Shared/components/read-less-trigger/read-less-trigger.component';

import { AuthLoginComponent } from 'Shared/components/modals/auth-modal/auth-login/auth-login.component';
import { AuthModalComponent } from 'Shared/components/modals/auth-modal/auth-modal.component';
import { ReviewsDisplayComponent } from 'Shared/components/reviews-display/reviews-display.component';
import { ReviewCardComponent } from 'Shared/components/review-card/review-card.component';
import { ZyperService } from 'Shared/services/third-parties/zyper.service';
import { MetaBaseComponent } from 'Shared/components/meta-base/meta-base.component';
import { MetaLocalBusinessComponent } from 'Shared/components/meta-local-business/meta-local-business.component';
import { MetaProductComponent } from 'Shared/components/meta-product/meta-product.component';
import { LocationReviewsComponent } from 'Shared/components/location-reviews/location-reviews.component';
import { HasRemoteConfigResolver } from 'Shared/guards/hasRemoteConfig.resolver';
import { HasSuccessfulPurchaseGuard } from 'Shared/guards/has-successful-purchase.guard';
import { ModalToShowGuard } from 'Shared/guards/modal-to-show.guard';
import { InstagramFeedComponent } from 'Shared/components/instagram-feed/instagram-feed.component';
import { TimingEventDirective } from 'Shared/directives/timing-event.directive';
import { CampaignCodesService } from 'Shared/services/campaign-codes.service';
import { QuickShopVTwoComponent } from 'Content/components/quick-shop-v-two/quick-shop-v-two.component';
import { FeatureBannerComponent } from 'Shared/components/feature-banner/feature-banner.component';
import { FeatureBannerDisplayService } from 'Shared/services/feature-banner-display.service';
import { ModalBaseComponent } from 'Shared/components/modal-base/modal-base.component';
import { AddressInlinePipe } from 'Shared/pipes/address-inline.pipe';
import { InAppMessageComponent } from 'Shared/components/in-app-message/in-app-message.component';
import { VideoPlayerComponent } from 'Shared/components/video-player/video-player.component';
import { MentionMeLaunchRefereeComponent } from 'Shared/components/third-parties/mention-me-launch-referee/mention-me-launch-referee.component';
import { ZendeskButtonComponent } from 'Shared/components/zendesk-button/zendesk-button.component';
import { ChatWindowComponent } from 'Shared/components/chat-window/chat-window.component';
import { AddressSuggestComponent } from 'Checkout/components/modals/address-suggest/address-suggest.component';
import { FavouritesComponent } from 'Shared/components/favourites/favourites.component';
import { FavouritesCtaComponent } from 'Shared/components/favourites-cta/favourites-cta.component';
import { FavouritesIndicatorComponent } from 'Shared/components/favourites-indicator/favourites-indicator.component';
import { NavBreadcrumbsComponent } from 'Shared/components/nav-breadcrumbs/nav-breadcrumbs.component';
import { MegaNavComponent } from 'Shared/components/mega-nav/mega-nav.component';
import { MegaNavDesktopComponent } from 'Shared/components/mega-nav-desktop/mega-nav-desktop.component';
import { MegaNavDesktopChildComponent } from 'Shared/components/mega-nav-desktop-child/mega-nav-desktop-child.component';
import { MegaNavMobileComponent } from 'Shared/components/mega-nav-mobile/mega-nav-mobile.component';
import { MegaNavMobileChildComponent } from 'Shared/components/mega-nav-mobile-child/mega-nav-mobile-child.component';
import { ResponsiveMegaNavItemDirective } from 'Shared/directives/responsive-mega-nav-item.directive';
import { PageCategoryMegaNavItemDirective } from 'Shared/directives/page-category-mega-nav-item.directive';
import { MaintenanceModalComponent } from 'Shared/components/modals/maintenance-modal/maintenance-modal.component';
import { MaintenanceListenerComponent } from 'Shared/components/maintenance-listener/maintenance-listener.component';
import { AddonCombinationImagePipe } from 'Shared/pipes/addon-combination-image.pipe';
import { PricePerDeliveryPipe } from 'Shared/pipes/price-per-delivery.pipe';
import { ContentSpotSeoFallbackComponent } from 'Shared/components/content-spot-seo-fallback/content-spot-seo-fallback.component';
import { AddressModalComponent } from 'Shared/components/address-modal/address-modal.component';
import { ResetPasswordModalComponent } from 'Account/auth/modals/reset-password-modal/reset-password-modal.component';
import { HasLoggedIn } from 'Shared/guards/hasLoggedIn.guard';
import { PopoverInfoDirective } from 'Shared/components/popover-info/popover-info.directive';
import { PopoverInfoComponent } from 'Shared/components/popover-info/popover-info.component';
import { InExperimentDirective } from 'Shared/directives/in-experiment.directive';
import { FooterBreadcrumbComponent } from 'Shared/components/footer-breadcrumb/footer-breadcrumb.component';
import { MetaBreadcrumbComponent } from 'Shared/components/meta-breadcrumb/meta-breadcrumb.component';
import { FormcontrolValidationMessagesComponent } from 'Shared/components/formcontrol-validation-messages/formcontrol-validation-messages.component';
import { CreateAccountComponent } from 'Account/auth/components/create-account/create-account.component';
import { ButtonComponent } from 'Shared/components/button/button.component';
import { ExpansionPanelComponent } from 'Shared/components/expansion-panel/expansion-panel.component';
import { ProcessBarComponent } from 'Shared/components/process-bar/process-bar.component';
import { DeliveryTrackingComponent } from 'Shared/components/delivery-tracking/delivery-tracking.component';
import { DeliveryTrackingContentComponent } from 'Shared/components/delivery-tracking-content/delivery-tracking-content.component';
import { FaqComponent } from 'Shared/components/faq/faq.component';
import { FeedbackComponent } from 'Shared/components/feedback/feedback.component';
import { AlertComponent } from 'Shared/components/alert/alert.component';
import { FeatureDirective } from 'Shared/directives/feature.directive';
import { ProductOptionListComponent } from 'Shared/components/product-option-list/product-option-list.component';
import { ProductCardImageComponent } from 'Shared/components/product-card-image/product-card-image.component';
import { ProductCardCompactComponent } from 'Shared/components/product-card-compact/product-card-compact.component';
import { HasProductDetailsResolver } from 'Shared/guards/hasProductDetails.resolver';
import { ModuleTabsComponent } from 'Shared/components/module-tabs/module-tabs.component';
import { SeoHreflangAlternateComponent } from 'Shared/components/seo-hreflang-alternate/seo-hreflang-alternate.component';
import { DiscountBarComponent } from 'Shared/components/discount-bar/discount-bar.component';
import { ProductPriceComponent } from 'Shared/components/product-price/product-price.component';
import { AppStoreLinkComponent } from 'Shared/components/app-store-link/app-store-link.component';
import { ProductAddonListModalComponent } from 'Checkout/components/product-addon-list-modal/product-addon-list-modal.component';
import { FullscreenLoadingSpinnerListenerComponent } from 'Shared/components/fullscreen-loading-spinner-listener/fullscreen-loading-spinner-listener.component';
import { CookieContentComponent } from 'Shared/components/cookie-content/cookie-content.component';
import { HasAddonRequirementModalGuard } from 'Shared/guards/hasAddonRequirementModal.guard';
import { GlobalIntersectionService } from 'Shared/services/global-intersection.service';
import { IntersectionObserverEventsDirective } from 'Shared/directives/intersection-observer-events.directive';
import { AddonTypePipe } from 'Checkout/pipes/addon-type.pipe';
import { HasInitialLoadedGuard } from 'Shared/guards/has-initial-loaded.guard';
import { MultipleGuardsInSerial } from 'Shared/guards/multiple-guards-in-serial.guard';
import { HasProductGuard } from 'Shared/guards/has-product.guard';
import { DeliveryShippingNoteComponent } from 'Shared/components/delivery-shipping-note/delivery-shipping-note.component';
import { AddonCardComponent } from 'Checkout/components/addon-card/addon-card.component';
import { tvDiscountDirective } from 'Shared/directives/tv-discount.directive';

import { IconComponent } from 'Shared/components/icon/icon.component';
import { FormContainerComponent } from 'Shared/components/form-container/form-container.component';
import { FormTextInputComponent } from 'Shared/components/form-text-input/form-text-input.component';
import { FormCheckboxInputComponent } from 'Shared/components/form-checkbox-input/form-checkbox-input.component';
import { RecipientSearchComponent } from 'Checkout/components/recipient-search/recipient-search.component';
import { FormRadioInputComponent } from 'Shared/components/form-radio-input/form-radio-input.component';
import { FormDropdownComponent } from 'Shared/components/form-dropdown/form-dropdown.component';
import { ClickOutsideDirective } from 'Shared/directives/click-outside.directive';
import { CallbackPipe } from 'Shared/pipes/callback.pipe';
import { FormFileUploadInputComponent } from 'Shared/components/form-file-upload-input/form-file-upload-input.component';
import { CarouselComponent } from 'Shared/components/carousel/carousel.component';
import { HorizontalTabsComponent } from 'Project/shared/components/horizontal-tabs/horizontal-tabs.component';
import { VerticalTabsComponent } from 'Shared/components/vertical-tab/vertical-tabs.component';
import { MentionMeModalComponent } from './components/third-parties/mention-me-modal/mention-me-modal.component';
import { BlogTagsPipe } from './pipes/blog-tags.pipe';
import { BlogCarouselComponent } from './components/blogs-carousel/blog-carousel.component';
import { HcDeliveryContentComponent } from 'Shared/components/help-center/hc-delivery-self-serve/hc-delivery-content/hc-delivery-content.component';
import { HcDeliveryCreditComponent } from 'Shared/components/help-center/hc-delivery-self-serve/hc-delivery-credit/hc-delivery-credit.component';
import { HcDeliveryDeliveriesComponent } from 'Shared/components/help-center/hc-delivery-self-serve/hc-delivery-deliveries/hc-delivery-deliveries.component';
import { HcDeliveryErrorComponent } from 'Shared/components/help-center/hc-delivery-self-serve/hc-delivery-error/hc-delivery-error.component';
import { HcDeliveryOrderSearchComponent } from 'Shared/components/help-center/hc-delivery-self-serve/hc-delivery-order-search/hc-delivery-order-search.component';
import { HcDeliveryResendComponent } from 'Shared/components/help-center/hc-delivery-self-serve/hc-delivery-resend/hc-delivery-resend.component';
import { HcDeliveryResendConfirmComponent } from 'Shared/components/help-center/hc-delivery-self-serve/hc-delivery-resend-confirm/hc-delivery-resend-confirm.component';
import { OrderSummaryComponent } from 'Shared/components/order-summary/order-summary.component';
import { SearchAddressComponent } from 'Shared/components/search-address/search-address.component';
import { PaymentIconsComponent } from 'Shared/components/payment-icons/payment-icons.component';
import { ActionableRowComponent } from 'Shared/components/actionable-row/actionable-row.component';
import { HcQualityOrderSearchComponent } from 'Shared/components/help-center/hc-quality-self-serve/hc-quality-order-search/hc-quality-order-search.component';
import { HcQualityDeliveriesComponent } from 'Shared/components/help-center/hc-quality-self-serve/hc-quality-deliveries/hc-quality-deliveries.component';
import { HcQualityIssuesComponent } from 'Shared/components/help-center/hc-quality-self-serve/hc-quality-issues/hc-quality-issues.component';
import { HcQualityStemsComponent } from 'Shared/components/help-center/hc-quality-self-serve/hc-quality-stems/hc-quality-stems.component';
import { HcQualityAdditionalComponent } from 'Shared/components/help-center/hc-quality-self-serve/hc-quality-additional/hc-quality-additional.component';
import { HcQualityResolutionComponent } from 'Shared/components/help-center/hc-quality-self-serve/hc-quality-resolution/hc-quality-resolution.component';
import { HcQualityCreditComponent } from 'Shared/components/help-center/hc-quality-self-serve/hc-quality-credit/hc-quality-credit.component';
import { HcQualityResendComponent } from 'Shared/components/help-center/hc-quality-self-serve/hc-quality-resend/hc-quality-resend.component';
import { HcQualityResendConfirmComponent } from 'Shared/components/help-center/hc-quality-self-serve/hc-quality-resend-confirm/hc-quality-resend-confirm.component';
import { HcQualityErrorsComponent } from 'Shared/components/help-center/hc-quality-self-serve/hc-quality-errors/hc-quality-errors.component';
import { ImageGalleryComponent } from 'Shared/components/image-gallery/image-gallery.component';
import { ModularContentProductCardComponent } from 'Browse/components/modular-content-product-card/modular-content-product-card.component';
import { SubscriptionFaqsComponent } from 'Shared/components/subscription-faqs/subscription-faqs.component';
import { SubscriptionFeaturesComponent } from 'Shared/components/subscription-features/subscription-features.component';
import { OrderSummaryNewComponent } from 'Shared/components/order-summary-new/order-summary-new.component';
import { AddressDisplayComponent } from 'Shared/components/address-display/address-display.component';
import { DeliveryTrackingLinkComponent } from 'Shared/components/delivery-tracking-link/delivery-tracking-link.component';
import { HcDeliveryHistoryComponent } from 'Shared/components/help-center/hc-delivery-self-serve/hc-delivery-history/hc-delivery-history.component';
import { AuthThirdPartyComponent } from 'Shared/components/modals/auth-modal/auth-third-party/auth-third-party.component';
import { AuthLoginEmailComponent } from 'Shared/components/modals/auth-modal/auth-login-email/auth-login-email.component';
import { AuthLoginPasswordComponent } from 'Shared/components/modals/auth-modal/auth-login-password/auth-login-password.component';
import { FullnameInputComponent } from 'Shared/components/fullname-input/fullname-input.component';
import { DetailsUserGuestComponent } from 'Checkout/components/details-user-guest/details-user-guest.component';
import { DetailsUserConsentComponent } from 'Checkout/components/details-user-consent/details-user-consent.component';
import { PaymentOptionChoiceComponent } from 'Shared/components/payment-option-choice/payment-option-choice.component';
import { PaypalComponent } from 'Shared/components/paypal/paypal.component';
import { GiftingHasProductGuard } from 'Shared/guards/giftingHasProduct.guard';
import { GiftingHasAddonRequirementModalGuard } from 'Shared/guards/giftingHasAddonRequirementModal.guard';
import { DiscountCodeInputComponent } from 'Shared/components/discount-code-input/discount-code-input.component';
import { BancontactComponent } from 'Shared/components/bancontact/bancontact.component';
import { IdealComponent } from 'Shared/components/ideal/ideal.component';
import { SofortComponent } from 'Shared/components/sofort/sofort.component';
import { WebPaymentRequestComponent } from 'Shared/components/web-payment-request/web-payment-request.component';
import { AuthCreateAccountComponent } from 'Shared/components/modals/auth-modal/auth-create-account/auth-create-account.component';
import { MentionMeBannerComponent } from './components/third-parties/mention-me-banner/mention-me-banner.component';
import { DeliveryAlertsComponent } from 'Account/my-order/components/delivery-alerts/delivery-alerts.component';
import { RegisterFormComponent } from 'Account/auth/components/register-form/register-form.component';
import { HasRewardsLoggedIn } from './guards/hasRewardsLoggedIn.guard';
import { HasGreetingCardsGuard } from 'Shared/guards/hasGreetingCards.guard';
import { MetaFaqComponent } from './components/meta-faq/meta-faq.component';
import { ProductAddonPickerComponent } from 'Checkout/components/product-addon-picker/product-addon-picker.component';

// Oasys Design System.
import { OasysLibModule } from './oasys.module';
import { MagicLinkModalComponent } from 'Shared/components/modals/magic-link-modal/magic-link-modal.component';
import { ContentfulOpenModalComponent } from 'Content/components/contentful-open-modal/contentful-open-modal.component';
import { FeaturedProductsCarouselComponent } from 'Project/browse/components/featured-products-carousel/featured-products-carousel.component';
import { HrefInterceptorService } from './services/href-interceptor.service';
import { ROUTING_HANDLER } from 'oasys-lib';
import { OasysRouterHandlerService } from './services/oasys-router-handler.service';
import { AuthEmailFormComponent } from 'Account/auth/components/auth-email-form/auth-email-form.component';
import { AuthPasswordFormComponent } from 'Account/auth/components/auth-password-form/auth-password-form.component';
import { HasAppToWebLoggedIn } from './guards/hasAppToWebLoggedIn.guard';
import { ProductCardContentComponent } from 'Browse/components/product-card-content/product-card-content.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    HttpClientModule,
    NgxPopperjsModule,
    BsDropdownModule.forRoot(),
    OasysLibModule,
    LoadingSpinnerComponent,
    RegisterFormComponent,
    CreateAccountComponent,
    ResetPasswordModalComponent,
    MentionMeBannerComponent,
    AuthEmailFormComponent,
    AuthPasswordFormComponent,
    PaypalComponent
  ],
  exports: [
    RegisterFormComponent,
    AuthEmailFormComponent,
    AuthPasswordFormComponent,
    LoadingSpinnerComponent,
    ActionableRowComponent,
    ProcessBarComponent,
    NgxPopperjsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DatePickerComponent,
    AddressSearchComponent,
    BsDropdownModule,
    AddressFormComponent,
    AddressFormNewComponent,
    TrackAsDirective,
    IntersectionObserverEventsDirective,
    StickyClassesDirective,
    StaticClickDirective,
    TimingEventDirective,
    ElasticDirective,
    CardEntryComponent,
    PricePipe,
    AddressPipe,
    AddressInlinePipe,
    CurrencyPipe,
    AsyncPipe,
    DayOfWeekPipe,
    FrequencyPipe,
    UserCreditPipe,
    SiteConfigPipe,
    DurationPipe,
    ToFixedPipe,
    FromNowPipe,
    ProductNamePipe,
    LocaleDatePipe,
    DiscountMessagePipe,
    SubscriptionSavingPipe,
    ImageSizePipe,
    PossessivePipe,
    TextReplacePipe,
    EllipsisPipe,
    RelativeDatePipe,
    LocaleRelativeDatePipe,
    AddonTypePipe,
    PricePerDeliveryPipe,
    AddonCombinationImagePipe,
    CardEntryComponent,
    PurchaseBasketComponent,
    PurchaseBasketDiscountMessageComponent,
    HrefInterceptorComponent,
    ReviewStarsComponent,
    ReviewStarsTrustpilotComponent,
    ExperimentComponent,
    ExperimentEventDirective,
    AddressSavedComponent,
    PageCategoryNavItemDirective,
    I18nComponent,
    I18nSelectorComponent,
    HrefActiveDirective,
    ServiceBarComponent,
    HrefActiveDirective,
    ContentDisplayComponent,
    ContentSpotComponent,
    SeoComponent,
    SeoHreflangAlternateComponent,
    FormValidationIconComponent,
    FormValidationMessagesComponent,
    FormValidationMessageComponent,
    OpenContentModalComponent,
    ComparePricePipe,
    StagingHelperComponent,
    LaunchModalComponent,
    GiftCardComponent,
    MentionMeOrderTrackingComponent,
    MentionMeIframeComponent,
    MentionMeLaunchRefereeComponent,
    ReviewSummaryComponent,
    ContentfulImageComponent,
    ContentfulOpenModalComponent,
    ContentfulPipeComponent,
    ContentfulEventComponent,
    VideoComponent,
    RedirectComponent,
    KeepShoppingComponent,
    LiveContactComponent,
    KeepShoppingComponent,
    DotPaginationComponent,
    OccasionOfferComponent,
    OccasionBackgroundsComponent,
    OccasionLaunchComponent,
    OccasionOfferMessageComponent,
    EmailPromptComponent,
    NameInputComponent,
    CountdownComponent,
    ReviewsStatsComponent,
    ReviewsTestimonialsCarouselComponent,
    ReviewTestimonialCardComponent,
    ReviewsSlimBannerComponent,
    AuthLoginComponent,
    AuthModalComponent,
    ReviewsDisplayComponent,
    ReviewCardComponent,
    MetaBaseComponent,
    MetaProductComponent,
    MetaLocalBusinessComponent,
    LocationReviewsComponent,
    InstagramFeedComponent,
    QuickShopVTwoComponent,
    VideoPlayerComponent,
    FeatureBannerComponent,
    ZendeskButtonComponent,
    FullscreenLoadingSpinnerListenerComponent,
    ChatWindowComponent,
    AddressSuggestComponent,
    FavouritesComponent,
    FavouritesCtaComponent,
    FavouritesIndicatorComponent,
    NavBreadcrumbsComponent,
    MegaNavComponent,
    MegaNavDesktopComponent,
    MaintenanceModalComponent,
    MaintenanceListenerComponent,
    ContentSpotSeoFallbackComponent,
    AddressModalComponent,
    ResetPasswordModalComponent,
    MagicLinkModalComponent,
    PopoverInfoDirective,
    PopoverInfoComponent,
    InExperimentDirective,
    FooterBreadcrumbComponent,
    MetaBreadcrumbComponent,
    FormcontrolValidationMessagesComponent,
    CreateAccountComponent,
    ButtonComponent,
    ExpansionPanelComponent,
    DeliveryTrackingComponent,
    DeliveryTrackingContentComponent,
    FeedbackComponent,
    DeliveryTrackingContentComponent,
    FaqComponent,
    AlertComponent,
    FeatureDirective,
    ProductOptionListComponent,
    ProductCardImageComponent,
    ModuleTabsComponent,
    ProductCardCompactComponent,
    DiscountBarComponent,
    ProductPriceComponent,
    AppStoreLinkComponent,
    ProductAddonListModalComponent,
    CookieContentComponent,
    DeliveryShippingNoteComponent,
    AddonCardComponent,
    FormTextInputComponent,
    FormContainerComponent,
    FormFileUploadInputComponent,
    IconComponent,
    tvDiscountDirective,
    FormCheckboxInputComponent,
    RecipientSearchComponent,
    FormRadioInputComponent,
    FormDropdownComponent,
    ClickOutsideDirective,
    CallbackPipe,
    CarouselComponent,
    HorizontalTabsComponent,
    VerticalTabsComponent,
    MentionMeModalComponent,
    BlogTagsPipe,
    BlogCarouselComponent,
    HcDeliveryContentComponent,
    HcDeliveryHistoryComponent,
    HcDeliveryCreditComponent,
    HcDeliveryDeliveriesComponent,
    HcDeliveryErrorComponent,
    HcDeliveryOrderSearchComponent,
    HcDeliveryResendComponent,
    HcDeliveryResendConfirmComponent,
    OrderSummaryComponent,
    OrderSummaryNewComponent,
    SearchAddressComponent,
    PaymentIconsComponent,
    HcQualityOrderSearchComponent,
    HcQualityDeliveriesComponent,
    HcQualityIssuesComponent,
    HcQualityStemsComponent,
    HcQualityAdditionalComponent,
    HcQualityResolutionComponent,
    HcQualityCreditComponent,
    HcQualityResendComponent,
    HcQualityResendConfirmComponent,
    HcQualityErrorsComponent,
    ImageGalleryComponent,
    ModularContentProductCardComponent,
    SubscriptionFaqsComponent,
    SubscriptionFeaturesComponent,
    AddressDisplayComponent,
    OasysLibModule,
    DeliveryTrackingLinkComponent,
    AuthThirdPartyComponent,
    AuthLoginEmailComponent,
    AuthLoginPasswordComponent,
    FullnameInputComponent,
    DetailsUserConsentComponent,
    DetailsUserGuestComponent,
    PaymentOptionChoiceComponent,
    FeaturedProductsCarouselComponent,
    DiscountCodeInputComponent,
    BancontactComponent,
    IdealComponent,
    SofortComponent,
    WebPaymentRequestComponent,
    AuthCreateAccountComponent,
    MentionMeBannerComponent,
    DeliveryAlertsComponent,
    MetaFaqComponent,
    ProductAddonPickerComponent,
    ProductCardContentComponent,
    PaypalComponent
  ],
  declarations: [
    InExperimentDirective,
    ActionableRowComponent,
    DetailsUserConsentComponent,
    DetailsUserGuestComponent,
    DeliveryTrackingContentComponent,
    DeliveryTrackingComponent,
    ExpansionPanelComponent,
    FaqComponent,
    ProcessBarComponent,
    PopoverInfoDirective,
    PopoverInfoComponent,
    PageCategoryMegaNavItemDirective,
    ResponsiveMegaNavItemDirective,
    ContentComponent,
    BwInteractionStateClassesDirective,
    ValidationMessagesComponent,
    HrefInterceptorComponent,
    DatePickerComponent,
    AddressSearchComponent,
    AddressFormComponent,
    AddressFormNewComponent,
    CardEntryComponent,
    AddressPipe,
    AddressInlinePipe,
    Highlight,
    TrackAsDirective,
    IntersectionObserverEventsDirective,
    StaticClickDirective,
    ElasticDirective,
    PricePipe,
    DayOfWeekPipe,
    FrequencyPipe,
    UserCreditPipe,
    SiteConfigPipe,
    DurationPipe,
    ToFixedPipe,
    FromNowPipe,
    ProductNamePipe,
    RelativeDatePipe,
    LocaleRelativeDatePipe,
    DiscountMessagePipe,
    ImageSizePipe,
    PossessivePipe,
    TextReplacePipe,
    EllipsisPipe,
    AddonTypePipe,
    PricePerDeliveryPipe,
    AddonCombinationImagePipe,
    PurchaseBasketComponent,
    PurchaseBasketDiscountMessageComponent,
    ReviewStarsComponent,
    ReviewStarsTrustpilotComponent,
    ExperimentComponent,
    ExperimentEventDirective,
    AddressSavedComponent,
    PageCategoryNavItemDirective,
    I18nComponent,
    I18nSelectorComponent,
    StickyClassesDirective,
    HrefActiveDirective,
    ServiceBarComponent,
    HrefActiveDirective,
    ContentDisplayComponent,
    ContentSpotComponent,
    SeoComponent,
    SeoHreflangAlternateComponent,
    FormValidationIconComponent,
    FormValidationMessagesComponent,
    FormValidationMessageComponent,
    FormValidationMessageComponent,
    OpenContentModalComponent,
    ComparePricePipe,
    StagingHelperComponent,
    LaunchModalComponent,
    GiftCardComponent,
    MentionMeOrderTrackingComponent,
    MentionMeIframeComponent,
    MentionMeLaunchRefereeComponent,
    ReviewSummaryComponent,
    ContentfulImageComponent,
    ContentfulOpenModalComponent,
    ContentfulPipeComponent,
    ContentfulEventComponent,
    VideoComponent,
    RedirectComponent,
    SeparatorBarComponent,
    LocaleDatePipe,
    ExperimentComponent,
    KeepShoppingComponent,
    LiveContactComponent,
    DotPaginationComponent,
    SubscriptionSavingPipe,
    OccasionOfferComponent,
    OccasionBackgroundsComponent,
    OccasionLaunchComponent,
    OccasionOfferMessageComponent,
    EmailPromptComponent,
    NameInputComponent,
    CountdownComponent,
    ReviewsStatsComponent,
    ReviewsSlimBannerComponent,
    ReadMoreTriggerComponent,
    ReviewsTestimonialsCarouselComponent,
    ReviewTestimonialCardComponent,
    ReadLessTriggerComponent,
    AuthLoginComponent,
    AuthModalComponent,
    ReviewsDisplayComponent,
    ReviewCardComponent,
    MetaBaseComponent,
    MetaProductComponent,
    MetaLocalBusinessComponent,
    LocationReviewsComponent,
    InstagramFeedComponent,
    TimingEventDirective,
    InAppMessageComponent,
    QuickShopVTwoComponent,
    VideoPlayerComponent,
    FeatureBannerComponent,
    ModalBaseComponent,
    ZendeskButtonComponent,
    FullscreenLoadingSpinnerListenerComponent,
    ChatWindowComponent,
    AddressSuggestComponent,
    FavouritesComponent,
    FavouritesCtaComponent,
    FavouritesIndicatorComponent,
    NavBreadcrumbsComponent,
    MegaNavComponent,
    MegaNavDesktopComponent,
    MegaNavDesktopChildComponent,
    MegaNavMobileComponent,
    MegaNavMobileChildComponent,
    MaintenanceModalComponent,
    MaintenanceListenerComponent,
    ContentSpotSeoFallbackComponent,
    AddressModalComponent,
    MagicLinkModalComponent,
    FooterBreadcrumbComponent,
    MetaBreadcrumbComponent,
    FormcontrolValidationMessagesComponent,
    ButtonComponent,
    ExpansionPanelComponent,
    FeedbackComponent,
    AlertComponent,
    FeatureDirective,
    ProductOptionListComponent,
    ProductCardImageComponent,
    ProductCardCompactComponent,
    ModuleTabsComponent,
    DiscountBarComponent,
    ProductPriceComponent,
    AppStoreLinkComponent,
    ProductAddonListModalComponent,
    CookieContentComponent,
    DeliveryShippingNoteComponent,
    AddonCardComponent,
    FormTextInputComponent,
    FormContainerComponent,
    FormFileUploadInputComponent,
    IconComponent,
    tvDiscountDirective,
    FormCheckboxInputComponent,
    RecipientSearchComponent,
    FormRadioInputComponent,
    FormDropdownComponent,
    ClickOutsideDirective,
    CallbackPipe,
    CarouselComponent,
    HorizontalTabsComponent,
    VerticalTabsComponent,
    MentionMeModalComponent,
    BlogTagsPipe,
    BlogCarouselComponent,
    HcDeliveryContentComponent,
    HcDeliveryHistoryComponent,
    HcDeliveryCreditComponent,
    HcDeliveryDeliveriesComponent,
    HcDeliveryErrorComponent,
    HcDeliveryOrderSearchComponent,
    HcDeliveryResendComponent,
    HcDeliveryResendConfirmComponent,
    OrderSummaryComponent,
    OrderSummaryNewComponent,
    SearchAddressComponent,
    PaymentIconsComponent,
    HcQualityOrderSearchComponent,
    HcQualityDeliveriesComponent,
    HcQualityIssuesComponent,
    HcQualityStemsComponent,
    HcQualityAdditionalComponent,
    HcQualityResolutionComponent,
    HcQualityCreditComponent,
    HcQualityResendComponent,
    HcQualityResendConfirmComponent,
    HcQualityErrorsComponent,
    ImageGalleryComponent,
    ModularContentProductCardComponent,
    SubscriptionFaqsComponent,
    SubscriptionFeaturesComponent,
    AddressDisplayComponent,
    DeliveryTrackingLinkComponent,
    AuthThirdPartyComponent,
    AuthLoginEmailComponent,
    AuthLoginPasswordComponent,
    FullnameInputComponent,
    PaymentOptionChoiceComponent,
    FeaturedProductsCarouselComponent,
    DiscountCodeInputComponent,
    BancontactComponent,
    IdealComponent,
    SofortComponent,
    WebPaymentRequestComponent,
    AuthCreateAccountComponent,
    DeliveryAlertsComponent,
    MetaFaqComponent,
    ProductAddonPickerComponent,
    ProductCardContentComponent
  ],
  providers: [
    WindowRefService,
    BackendService,
    HasConfig,
    PopulatedPurchase,
    TranslationsServiceService,
    DateUtilsService,
    LocationService,
    AddressPipe,
    AddressInlinePipe,
    ComparePricePipe,
    PricePipe,
    CurrencyPipe,
    AsyncPipe,
    Highlight,
    DayOfWeekPipe,
    FrequencyPipe,
    ToFixedPipe,
    FromNowPipe,
    DiscountMessagePipe,
    LocaleDatePipe,
    SubscriptionSavingPipe,
    PossessivePipe,
    TextReplacePipe,
    EllipsisPipe,
    InvoiceService,
    FileService,
    TicketService,
    ServiceBarService,
    CustomKeysService,
    ReviewsService,
    ReviewsModelService,
    UntilIdleService,
    ImageSizePipe,
    OccasionRemindersModelService,
    UntilIdleService,
    ZyperService,
    HasRemoteConfigResolver,
    HasAddonRequirementModalGuard,
    GiftingHasProductGuard,
    HasGreetingCardsGuard,
    GiftingHasAddonRequirementModalGuard,
    HasProductDetailsResolver,
    HasSuccessfulPurchaseGuard,
    MultipleGuardsInSerial,
    HasProductGuard,
    HasInitialLoadedGuard,
    ModalToShowGuard,
    CampaignCodesService,
    GlobalIntersectionService,
    FeatureBannerDisplayService,
    AddonTypePipe,
    PricePerDeliveryPipe,
    AddonCombinationImagePipe,
    IntersectionService,
    HasLoggedIn,
    HasAppToWebLoggedIn,
    HasRewardsLoggedIn,
    BlogTagsPipe,
    HrefInterceptorService,
    { provide: ROUTING_HANDLER, useClass: OasysRouterHandlerService }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
