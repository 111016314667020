import { Injectable } from '@angular/core';
import { Product, AddonRequirement } from 'Shared/classes/product';
import { BackendService, APISerialisedJSONResponse } from 'Shared/services/backend.service';
import * as dayjs from 'dayjs';
import { environment } from 'Environments/environment';
import { DiscountInfo, DiscountType } from 'Shared/classes/discount-info';
import { Price } from 'Shared/classes/price';
import { WindowRefService } from 'Shared/services/window.service';
import { RangeProductFilterParams, RangeProductParams, RangeProductFilter } from 'Shared/classes/range-products';
@Injectable({
  providedIn: 'root'
})
export class RangeProductModelService {
  RANGE_DISCOVERY_API_VERSION = '2023-08-15';

  constructor(private backend: BackendService, private windowRefService: WindowRefService) {}

  static fromRangePayload(res): Product {
    const attr = res.attributes;
    attr.media = attr.media && attr.media.length ? attr.media : [];

    const product = new Product();
    product.id = res.id;
    product.slug = attr.slug;
    product.name = attr.name;
    product.bundleOnly = attr.bundle_only || false;
    product.singleOnly = attr.single_only || false;
    product.subscriptionOnly = attr.subscription_only || false;
    product.lilyFree = attr.lily_free;

    product.over18Only = attr.eighteen_plus;
    product.collectionName = attr.collection_name;
    product.collectionId = attr.collection_id;
    product.isPreorder = attr.is_pre_order;
    product.isInStock = attr.in_stock;
    product.latestShippingOptionCutoff = attr.latest_shipping_option_cut_off ? dayjs(attr.latest_shipping_option_cut_off) : undefined;
    product.label = attr.label;

    product.description = attr.description;
    product.longDescription = attr.long_description;
    product.shortDescription = attr.email_description;

    product.imageUrls = attr.media.length ? attr.media.map((m) => m.url) : attr.imageUrls || [];
    product.addonPrimaryImage = attr.media.length
      ? attr.media.find((m): boolean => m.tags_web.indexOf('addon-primary-image') > -1)?.url
      : undefined;

    // SKU_IMAGE_SWAP EXPERIMENT
    product.skuImageSwap = [];
    (attr.media || []).forEach((img): number => product.skuImageSwap.push({ url: img.url, tag: img.tags_web }));

    product.videoUrl = attr.video_url;
    product.videoThumbnail = attr.video_thumbnail_url;

    if ((res as APISerialisedJSONResponse<'/v2/skus/:productId'>).bouquet_images) {
      product.imageUrls = (res as APISerialisedJSONResponse<'/v2/skus/:productId'>).bouquet_images
        .filter((r): boolean => r.kind === 'letterbox-main')
        .map((image): string => image.urls.website_carousel.x1);
    }
    product.filterAttributes = attr.sku_attributes;
    product.upsells = [];

    /**
     * Sometimes the backend doens't give us the best data
     * So we hide this fact, and show the products regarless of bad data...
     */
    try {
      product.discountAddon = (attr.discount_info || [])
        .map((d) => {
          const discount = new DiscountInfo();
          discount.id = d.id;
          discount.name = d.name;
          discount.slug = d.slug;
          discount.type = d.type;
          discount.price = new Price(attr.currency, 1, d.price[0].price_pennies, d.price[0].price_pennies_discounted);
          discount.imageUrls = d.media.map((m) => m.url);
          return discount;
        })
        .find((di) => di.type === DiscountType.Addon);
    } catch (e) {}

    product.appearingFrom = dayjs(attr.appearing_from);
    product.appearingTo = dayjs(attr.appearing_to);
    product.deliverableFrom = dayjs(attr.deliverable_from).startOf('day');
    product.deliverableTo = dayjs(attr.deliverable_to).endOf('day');

    product.rating = {
      count: attr.rating_count,
      average: attr.rating_average
    };
    product.tags = (attr.tags || []).map((t) => t.toLowerCase());
    product.type = attr.product_kind;

    product.addonRequirements = Object.entries(attr.addon_requirements || {}).map(
      ([key, value]: [
        string,
        {
          min: number;
          max: number;
          default_addon_sku_id: number;
        }
      ]) => ({
        kind: key as AddonRequirement['kind'],
        min: value.min,
        max: value.max,
        defaultAddonId: value.default_addon_sku_id
      })
    );

    const giftCardRelatedRequirements = product.getGreetingCardAddonRequirement();

    // Hack - ensure that every sku has an addon requirement for an optional gift card
    // This should be set-up in admin
    if (!giftCardRelatedRequirements) {
      product.addonRequirements.push({
        min: 1,
        max: 1,
        kind: 'gift_card',
        defaultAddonId: undefined
      });
    }

    product.setPricingV2(attr.currency, attr.prices);

    // Self purchase subscription
    const hasSelfPurchaseSubscriptionTag = (attr.tags || []).indexOf('self-purchase-subscription') > -1;
    if (hasSelfPurchaseSubscriptionTag) {
      product.isSelfPurchaseSubscription = hasSelfPurchaseSubscriptionTag;
    }

    return product;
  }

  /**
   * Convert filters object into series of params parsable by range products api
   * @param filters
   */
  filtersAsQueryObjects(filters: RangeProductFilterParams): Record<string, string[]> {
    if (!filters) {
      return;
    }

    return Object.keys(filters).reduce((init, key): Record<string, string[]> => {
      if (Array.isArray(filters[key])) {
        init[`filters[${key}][]`] = filters[key];
      } else {
        init[`filters[${key}]`] = filters[key];
      }
      return init;
    }, {} as Record<string, string[]>);
  }

  /**
   * Get all products from BE
   * @param brandId
   * @param country
   * @param pageSize
   */
  getAvailableRangeProducts(params: RangeProductParams): Promise<{ availableProducts: Product[]; availableFilters: RangeProductFilter[] }> {
    const backendRangeUrl = environment.backendRangeUrl.replace(':date', this.RANGE_DISCOVERY_API_VERSION);
    const {
      bouquetSlug,
      brandId,
      country,
      filteredTagonly,
      filters,
      orderIndex,
      pageSize,
      prioritisedTag,
      sortType,
      user,
      validatedDiscountCode
    } = params;

    return this.backend
      .get(null, backendRangeUrl, {
        useUrlAsCache: true,
        sendExperiments: ['API_'],
        useFullUrlFromInput: true,
        params: {
          brand: brandId,
          shipping_country_id: country.id,
          user_slug: user?.slug,
          'page[size]': pageSize,
          sort: sortType ? sortType : undefined,
          // On the BE we can support the prioritization of multiple slugs, but we currently only support one on the FE
          'prioritize[slugs][]': bouquetSlug ?? undefined,
          // On the BE we can support the prioritization of multiple tags, but we currently only support one on the FE
          'prioritize[tags][]': prioritisedTag ?? undefined,
          'filters[tag]': filteredTagonly ?? undefined,
          device_fingerprint: JSON.parse(this.windowRefService.nativeWindow['bwFingerprint'] || '""').replace(/\"/gim, ''),
          discount_code: validatedDiscountCode?.toLowerCase(),
          first_item_in_purchase: orderIndex === 0,
          ...this.filtersAsQueryObjects(filters)
        }
      })
      .then((res): { availableProducts: Product[]; availableFilters: RangeProductFilter[] } => {
        const availableProducts = res && res.data ? res.data.map((r): Product => RangeProductModelService.fromRangePayload(r)) : [];
        const availableFilters = res && res.filters ? res.filters : [];
        return { availableProducts, availableFilters };
      })
      .catch((): { availableProducts: Product[]; availableFilters: RangeProductFilter[] } =>
        // TODO: in case this fails it should fallback to the old v2/availability/products
        ({ availableProducts: [], availableFilters: [] })
      );
  }
}
